import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import './App.css';
import { Header } from './Partials/Header';
import { Footer } from './Partials/Footer';
import { Home } from "./Pages/Home";
import { NotFound } from "./Pages/NotFound";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import { AuthProvider, useAuthUser } from "react-auth-kit";
import { Solutions } from "./Pages/Solutions";
import { Aboutus } from "./Pages/Aboutus";
import { Contact } from "./Pages/Contact";
import { TermsOfUse } from "./Pages/TermsOfUse";
import { Privacy } from "./Pages/Privacy";
import i18next from "i18next";
import { Suspense } from "react";

const ProtectedRoute = ({ redirectPath = '/signin', }) => {
  const auth = useAuthUser();
  if (!auth()?.emailVerified) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

function App() {

  return (
    <>
      <BrowserRouter>
          <AuthProvider authType={'localstorage'}
            authName={'_auth'}>
            <main>
              <Header />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/Solutions" element={<Solutions />} />
                <Route path="/Aboutus" element={<Aboutus />} />
                <Route path="/Contact" element={<Contact />} />
                <Route path="/TermsOfUse" element={<TermsOfUse />} />
                <Route path="/Privacy" element={<Privacy />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
              <Footer />
              {/* <ToastContainer /> */}
            </main>
          </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
