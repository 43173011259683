export const NotFound = () => {
    return (
        <div className="error-section padding-top padding-bottom bg_img" data-background="assets/images/error-bg.png">
            <div className="container">
                <div className="error-wrapper">
                    <div className="error-thumb">
                        <img src="assets/images/error.png" alt="error" />
                    </div>
                    <h4 className="title">Return to the <a href="index.html">homepage</a></h4>
                </div>
            </div>
        </div>
    );
}