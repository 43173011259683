import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Footer = () => {
    const { t } = useTranslation();

    return (
        <>
            <div>
                <svg viewBox="0 0 1440 185" preserveAspectRatio="none" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" className="d-block  text-primary" width="100%" height="160">
                    <path d="M0 185 H 1440 V 60 C 1114 355 700 35 516 35 C 333 35 246 199 0 60 V 185 Z" />
                </svg>
            </div>
            <div data-pgc="footer_">
                <section className="bg-primary pb-5 pt-5 text-white-50">
                    <div className="container pb-3 pt-3">
                        <div className="align-items-center gy-3 row">
                            <div className="col-lg-7">
                                <h2 className="fw-bold h2 text-capitalize text-white">{t('Home.subscribeNews')}</h2>
                                <p className="text-white">{t('Home.offres')}</p>
                            </div>
                            <div className="col-lg-5">
                                <div className="align-items-center g-2 row">
                                    <div className="col-sm">
                                        <input type="text" id="email_sus" lass="form-control pb-2 pe-3 ps-3 pt-2" placeholder="Entrez votre adresse e-mail..." style={{ height: "43px" }} />
                                    </div>
                                    <div className="col-sm-auto text-end">
                                        <button type="button" className="btn btn-secondary pb-3 pt-3 rounded-0 text-uppercase w-100" id="submitButton1"> <span id="spinner1" className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ display: "none" }}></span> <span id="text_span1"> {t('Globals.subscribe')} </span>
                                        </button>
                                    </div>
                                </div>
                                <form role="form" className="">
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <footer className="bg-dark pt-5 text-center text-md-start text-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 py-3 text-center"> <a
                            className="align-items-center d-inline-flex flex-column fw-bold gap-2 h3 lh-1 link-info mb-3 text-decoration-none"
                            href="index.html"> <img src="logo.png" width="170" height="67" className="mb-1 mt-5" /></a>
                            <ul className="list-unstyled">
                                <li className="mb-1">Nkoabang, Centre, Yaounde Cameroon</li>
                                <li className="mb-1"><a href="#" className="link-info">+237 655 555 555</a>
                                </li>
                                <li className="mb-1"><a href="#" className="link-info">contact@cotradsarl.com</a>
                                </li>
                            </ul>
                            <div className="d-inline-flex flex-wrap gap-2 py-1"> <a href="#" aria-label="facebook"
                                className="link-light"> <svg viewBox="0 0 24 24" fill="currentColor" width="20"
                                    height="20">
                                    <path
                                        d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z" />
                                </svg> </a> <a href="#" aria-label="twitter" className="link-light"> <svg
                                    viewBox="0 0 24 24" fill="currentColor" width="20" height="20">
                                    <path
                                        d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z" />
                                </svg> </a> <a href="#" aria-label="instagram" className="link-light"> <svg
                                    viewBox="0 0 24 24" fill="currentColor" width="20" height="20">
                                    <path
                                        d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" />
                                </svg> </a> <a href="#" aria-label="linkedin" className="link-light"> <svg
                                    viewBox="0 0 24 24" fill="currentColor" width="20" height="20">
                                    <path
                                        d="M6.94 5a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z" />
                                </svg> </a> <a href="#" aria-label="youtube" className="link-light"> <svg
                                    viewBox="0 0 24 24" fill="currentColor" width="20" height="20">
                                    <path
                                        d="M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5l6-3.5-6-3.5v7z" />
                                </svg> </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 order-lg-first py-3">
                            <h2 className="fw-bold h5 mb-0 text-info">{t('footer.Info')}</h2>
                            <hr className="d-inline-block mb-4 w-25" />
                            <div className="gy-3 row row-cols-sm-2">
                                <div>
                                    <ul className="list-unstyled">
                                        <li className="mb-3"> <Link onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} to="Contact" className="link-light">{t('footer.Contact')}</Link>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled">
                                        <li className="mb-3"> <Link onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} to="Aboutus" className="link-light">{t('footer.Aboutus')}</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 offset-lg-1 py-3">
                            <h2 className="fw-bold h5 mb-0 text-info">{t('footer.Opening')}</h2>
                            <hr className="d-inline-block mb-4 w-25" />
                            <ul className="list-unstyled">
                                <li className="align-items-center d-flex justify-content-between mb-1">
                                    <span>{t('footer.Monday')}&nbsp;</span><span>8 AM - 10 PM</span>
                                </li>
                                <li className="align-items-center d-flex justify-content-between mb-1">
                                    <span>{t('footer.Tuesday')}&nbsp;</span><span>8 AM - 10 PM</span>
                                </li>
                                <li className="align-items-center d-flex justify-content-between mb-1">
                                    <span>{t('footer.Wednesday')}&nbsp;</span><span>8 AM - 10 PM</span>
                                </li>
                                <li className="align-items-center d-flex justify-content-between mb-1">
                                    <span>{t('footer.Thursday')}&nbsp;</span><span>8 AM - 10 PM</span>
                                </li>
                                <li className="align-items-center d-flex justify-content-between mb-1">
                                    <span>{t('footer.Friday')}&nbsp;</span><span>8 AM - 10 PM</span>
                                </li>
                                <li className="align-items-center d-flex justify-content-between mb-1">
                                    <span>{t('footer.Saturday')}&nbsp;</span><span>12 PM - 8 PM</span>
                                </li>
                                <li className="align-items-center d-flex justify-content-between mb-1">
                                    <span>{t('footer.Sunday')}&nbsp;</span><span>12 PM - 8 PM</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="pb-3 pt-3">
                        <hr className="border-secondary mt-0" />
                        <div className="align-items-center d-flex justify-content-around row">
                            <div className="col-md pb-2 pt-2">
                                <p className="mb-0 text-center">&copy; {new Date().getFullYear()}. {t('footer.Right')} - Leden Go</p>
                            </div>
                            <div className="col-md-auto pb-2 pt-2"><Link onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} to="Privacy" className="link-light">{t('footer.policy')}</Link>
                                | <Link onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} to="TermsOfUse" className="link-light">{t('footer.terms')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}