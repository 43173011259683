import { useEffect } from "react";

export const Privacy = () => {

    return (
        <>
            <section className="bg-secondary  position-relative poster text-center text-white-50" style={{ backgroundImage: `url("https://images.unsplash.com/photo-1493135637657-c2411b3497ad?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDkyMnwwfDF8c2VhcmNofDN8fGxpdnJhaXNvbiUyMGJsYWNrfGVufDB8fHx8MTY5NjkzNzAwOXww&ixlib=rb-4.0.3&q=80&w=400")`, height: "250px", marginTop: "72px" }}>
                <div className="align-items-center container d-flex h-100 justify-content-center">
                    <div>
                        <h1 className="display-3 fw-bold  text-capitalize text-white" data-pg-ia='{"l":[{"trg":"now","a":"fadeInUp"}]}'>Privacy Policy</h1><a href="index.html" className="link-secondary"><span>Home&nbsp;</span></a><span>&nbsp;-&gt; Privacy Policy</span>
                    </div>
                    <div className="justify-content-center mb-sm-5 mt-sm-5  pb-sm-5 pt-sm-5 row">
                        <div className="col-md-10 col-xl-9 pb-5 pt-5">
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-light pb-5 pt-5">
                <div className="container pb-5 pt-5">
                    <div className="row">
                        <div className="col-lg-10 me-auto ms-auto">
                            {/* <h3 className="fw-bold h2 mb-4 text-primary">Découvrez une nouvelle manière révolutionnaire de simplifier vos livraisons</h3>
                            <img src="https://images.unsplash.com/photo-1581287053822-fd7bf4f4bfec?ixid=M3wyMDkyMnwwfDF8c2VhcmNofDR8fGFwbGljYXRpb258ZW58MHx8fHwxNjk3MDk4MTY2fDA&ixlib=rb-4.0.3q=85&fm=jpg&crop=faces&cs=srgb&w=1290&h=537&fit=crop" className="img-fluid mb-5 w-100" data-pg-ia-scene='{"dir":"a","s":"onEnter","o":"-800px","l":[{"a":"zoomIn"}]}' /> */}

                            <h2 className="h3 mb-1 text-secondary">Introduction</h2>
                            <p className="mb-4">
                                <p>Leden SARL accorde une grande importance à la protection de la vie privée de nos utilisateurs. La présente politique de confidentialité décrit comment nous collectons, utilisons et partageons les informations personnelles que vous nous fournissez ou que nous collectons lorsque vous utilisez notre application mobile de transport de marchandise Leden Go.</p>
                                <h2 className="h3 mb-2 mt-2 text-secondary">Définitions</h2>

                                <p className="mb-2"> « Informations personnelles » désignent toute information relative à une personne physique identifiée ou identifiable, telle que votre nom, votre adresse e-mail, votre numéro de téléphone ou votre adresse IP.</p>
                                <p className="mb-2">« Utilisateur » désigne toute personne qui accède ou utilise l'Application.</p>

                                <h2 className="h3 mb-2 mt-2 text-secondary">Collecte d'Informations</h2>


                                <p>Nous collectons des informations personnelles auprès de vous lorsque vous créez un compte, utilisez l'Application ou interagissez avec nous. Les informations personnelles que nous collectons peuvent inclure :</p>

                                <ul>
                                    <li>Vos coordonnées, telles que votre nom, votre adresse e-mail, votre numéro de téléphone et votre adresse postale ;</li>
                                    <li>Les informations de votre appareil, telles que votre adresse IP, votre type d'appareil et votre système d'exploitation ;</li>
                                    <li>Les informations de votre utilisation de l'Application, telles que les services que vous avez réservés et les trajets que vous avez effectués ;</li>
                                    <li>Les informations que vous nous fournissez volontairement, telles que vos commentaires ou vos questions.</li>
                                </ul>


                                <h2 className="h3 mb-2 mt-2 text-secondary">Utilisation des informations</h2>

                                <p>Nous utilisons les informations personnelles que nous collectons pour :</p>
                                <ul>
                                    <li>Vous fournir l'Application et ses fonctionnalités ;</li>
                                    <li>Vous contacter pour vous fournir des informations ou des offres ;</li>
                                    <li>Améliorer l'Application et nos services ;</li>
                                    <li>Répondre à vos demandes et questions ;</li>
                                    <li>Prévenir et détecter les fraudes et les abus.</li>
                                </ul>
                                <h2 className="h3 mb-2 mt-2 text-secondary">Partage des informations</h2>

                                <p>Nous ne partageons vos informations personnelles qu'avec les tiers suivants :</p>
                                <ul>
                                    <li>Nos partenaires commerciaux qui nous aident à fournir l'Application et ses fonctionnalités ;</li>
                                    <li>Les autorités publiques si nous sommes tenus de le faire par la loi.</li>
                                </ul>

                                <h2 className="h3 mb-2 mt-2 text-secondary  ">Vos droits</h2>

                                <p>Vous avez les droits suivants en matière de protection de la vie privée :</p>
                                <ul>
                                    <li>Le droit d'accéder à vos informations personnelles ;</li>
                                    <li>Le droit de demander la correction ou la suppression de vos informations personnelles ;</li>
                                    <li>Le droit de vous opposer au traitement de vos informations personnelles ;</li>
                                    <li>Le droit de retirer votre consentement au traitement de vos informations personnelles.</li>
                                </ul>

                                {/* <p>Si une réclamation ou un litige ne peut être résolu à l'amiable, il sera soumis à l'arbitrage conformément aux règles de l'Association des arbitres commerciaux internationaux (AAA).</p> */}

                                <h2 className="h3 mb-2 mt-2 text-secondary">Comment exercer vos droits ?</h2>

                                <p>Pour exercer vos droits en matière de protection de la vie privée, veuillez nous contacter à l'adresse suivante :</p>

                                <p>Centre, Yaounde Cameroon</p>

                                <p>contact@cotradsarl.com</p>

                                <p>+237 655 555 555</p>

                                <h2 className="h3 mb-2 mt-2 text-secondary">Modifications de la Politique</h2>

                                <p>Nous pouvons modifier la présente Politique à tout moment. Les modifications seront effectives dès leur publication sur l'Application.</p>

                                <h2 className="h3 mb-2 mt-2 text-secondary">Contact</h2>

                                <p>Pour toute question ou demande concernant les présentes Conditions, veuillez contacter Leden SARL à l'adresse suivante :</p>

                                <p>Centre, Yaounde Cameroon</p>

                                <p>contact@cotradsarl.com</p>

                                <p>+237 655 555 555</p>

                                <h2 className="h3 mb-2 mt-2 text-secondary">Informations supplémentaires</h2>

                                <p>En utilisant l'Application, vous acceptez les conditions de la présente Politique.</p>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}