import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "../i18n";

export const Home = () => {
    const { t } = useTranslation();
    
    return (
        <>
            <section className="bg-secondary pb-5 position-relative poster text-center text-white-50" style={{ backgroundImage: `url('https://images.unsplash.com/photo-1493135637657-c2411b3497ad?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDkyMnwwfDF8c2VhcmNofDN8fGxpdnJhaXNvbiUyMGJsYWNrfGVufDB8fHx8MTY5NjkzNzAwOXww&ixlib=rb-4.0.3&q=80&w=400')` }}>
                <nav className="navbar navbar-dark navbar-expand-lg pb-0 pt-0 shadow-none">
                    <div className="container"> <Link onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} to="/"><img src="logo.png" width="170" height="67" className="" /></Link>
                        <button className="navbar-toggler rounded" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown-2" aria-controls="navbarNavDropdown-2" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse " id="navbarNavDropdown-2">
                            <ul className="me-auto ms-auto navbar-nav">
                                <li className="nav-item"> <Link onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} className="nav-link p-lg-3 pe-xl-4 ps-xl-4" to="/">{t('header.NavBar.Home')}</Link>
                                </li>
                                <li className="nav-item"> <Link onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} className="nav-link p-lg-3 pe-xl-4 ps-xl-4" to="Solutions">{t('header.NavBar.Solution')}</Link>
                                </li>
                                <li className="nav-item"> <Link onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} className="nav-link p-lg-3 pe-xl-4 ps-xl-4" to="Aboutus">{t('header.NavBar.Aboutus')}</Link>
                                </li>
                                <li className="nav-item"> <Link onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} className="nav-link p-lg-3 pe-xl-4 ps-xl-4" to="Contact">{t('header.NavBar.Contact')}</Link>
                                </li>
                            </ul>
                            <div className="d-inline-flex flex-wrap gap-3 py-1"> <a href="#" aria-label="facebook" className="link-light text-decoration-none"> <svg viewBox="0 0 24 24" fill="currentColor" width="1.25em" height="1.25em">
                                <path d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z" />
                            </svg> </a> <a href="#" aria-label="twitter" className="link-light text-decoration-none"> <svg viewBox="0 0 24 24" fill="currentColor" width="1.25em" height="1.25em">
                                <path d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z" />
                            </svg> </a> <a href="#" aria-label="instagram" className="link-light text-decoration-none"> <svg viewBox="0 0 24 24" fill="currentColor" width="1.25em" height="1.25em">
                                <path d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" />
                            </svg> </a>
                            </div>
                            <div className="dropdown">
                                <button className="dropbtn">
                                    <a href="#" className="pe-3"><img src={i18n.resolvedLanguage === "en" ? "https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/1280px-Flag_of_the_United_States.svg.png" : "https://upload.wikimedia.org/wikipedia/en/thumb/c/c3/Flag_of_France.svg/1280px-Flag_of_France.svg.png"} width="35" height="25" /></a>
                                    <i className="fa fa-caret-down"></i>
                                </button>
                                <div className="dropdown-content">
                                    <button onClick={(e) => {
                                        e.preventDefault();
                                        i18n.changeLanguage('en')
                                        window.location.reload();
                                    }} style={{textDecoration: "none"}}>
                                        <img src="https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/1280px-Flag_of_the_United_States.svg.png" width="35" height="25" />
                                    </button>
                                    <button onClick={(e) => {
                                        e.preventDefault();
                                        i18n.changeLanguage('fr')
                                        window.location.reload();
                                    }} style={{textDecoration: "none"}}>
                                        <img src="https://upload.wikimedia.org/wikipedia/en/thumb/c/c3/Flag_of_France.svg/1280px-Flag_of_France.svg.png" width="35" height="25" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="container mb-5 pb-5 pt-5">
                    <div className="justify-content-center mb-sm-5 mt-sm-5  pb-sm-5 pt-sm-5 row">
                        <div className="col-md-10 col-xl-9 pb-5 pt-5">
                            <p className="fw-bold h4 text-white" data-pg-ia='{"l":[{"trg":"now","a":"fadeInDown"}]}'>{t('Home.citation')}</p>
                            <h1 className="display-3 fw-bold mb-4 text-capitalize text-white" data-pg-ia='{"l":[{"trg":"now","a":"fadeInUp"}]}'>{t('Home.titleHeader')}</h1>
                            <div className="d-inline-flex flex-wrap gap-2 justify-content-center" data-pgc-define="download">
                                <a href=""><img src="assets/img/app-store-google-play.png" width="176" /></a><a href=""><img src="assets/img/app_store.png" width="200" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5 pt-5">
                <div className="container pt-5">
                    <div className="align-items-center mb-5 row">
                        <div className="col-lg-6 pb-3 pt-3" data-pg-ia-scene='{"dir":"h","s":"auto","l":[{"a":"fadeInLeft"}]}'>
                            <h2 className="h5 text-warning">{t('Home.welcome')}</h2>
                            <h3 className="fw-bold h1  text-capitalize text-dark" data-pg-ia-scene='{"s":"onEnter","l":[{"t":"$main #gt# section:nth-of-type(2) #gt# .container #gt# .align-items-center #gt# div:nth-of-type(1)","a":"fadeInLeft"}],"o":"-1000px"}'>{t('Home.subTitle')}</h3>
                            <p className="mb-4">{t('Home.welcomeDesc')}</p> <Link onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} to="Solutions" className="btn btn-secondary pb-2 pe-4 ps-4 pt-2 text-primary">{t('Globals.learnMore')}</Link>
                        </div>
                        <div className="col-lg-6 pb-3 pt-3">
                            <div className="align-items-center row">
                                <div className="col-10 col-sm-6 pb-3 pt-3">
                                    <img src="https://images.unsplash.com/photo-1449247666642-264389f5f5b1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDkyMnwwfDF8c2VhcmNofDR8fGxpdnJhaXNvbiUyMGJsYWNrfGVufDB8fHx8MTY5NjkzNzAwOXww&ixlib=rb-4.0.3&q=80&w=400" className="img-fluid mb-4 rounded-3" alt="..." width="1000" height="650" data-pg-ia-scene='{"dir":"a","s":"onEnter","o":"-800px","l":[{"a":"fadeInDown"}]}' />
                                    <img src="https://images.unsplash.com/photo-1607273685680-6bd976c5a5ce?ixid=M3wyMDkyMnwwfDF8c2VhcmNofDIzfHxsaXZyYWlzb258ZW58MHx8fHwxNjk2OTM2NjYxfDA&ixlib=rb-4.0.3q=85&fm=jpg&crop=faces&cs=srgb&w=300&h=240&fit=crop" className="img-fluid rounded-3" alt="..." width="1000" height="650" data-pg-ia-scene='{"dir":"a","dbg":"true","s":"onEnter","o":"-800px","l":[{"a":"fadeInUp"}]}' />
                                </div>
                                <div className="col-10 col-sm-6 pb-3 pt-3">
                                    <img src="https://images.unsplash.com/photo-1551825687-f9de1603ed8b?ixid=M3wyMDkyMnwwfDF8c2VhcmNofDYzfHxsaXZyYWlzb258ZW58MHx8fHwxNjk2OTM5MjI1fDA&ixlib=rb-4.0.3q=85&fm=jpg&crop=faces&cs=srgb&w=300&h=390&fit=crop" className="img-fluid rounded-3" alt="..." width="1000" height="650" data-pg-ia-scene='{"dir":"a","dbg":"true","s":"onEnter","o":"-800px","l":[{"a":"fadeInRight"}]}' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-warning col-xl-10 mx-auto p-4 rounded-3">
                        <div className="justify-content-center row row-cols-lg-3 row-cols-md-2">
                            <div className="pb-3 pt-3" data-pg-ia-scene='{"dir":"a","s":"onEnter","l":[{"a":"zoomIn"}],"o":"-1000px"}'>
                                <div className="gx-0 gy-3 row">
                                    <div className="col-md-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" fill="currentColor" width="4rem" height="4rem" className="me-3 text-primary">
                                            <path d="M116.646 56.252H11.351l.097-2.088C12.735 26.307 35.819 4.485 64 4.485c28.18 0 51.264 21.821 52.551 49.678l.095 2.089zM15.578 52.26h96.842C110.209 27.544 89.334 8.478 64 8.478c-25.335 0-46.21 19.066-48.422 43.782z" />
                                            <path d="M99.604 48.64c-2.107-9.755-7.57-18.675-15.389-25.116l2.541-3.081c8.504 7.01 14.455 16.724 16.75 27.354l-3.902.843zM117.174 66.565H10.821c-2.451 0-4.163-2.883-4.163-7.01 0-4.139 1.712-7.029 4.163-7.029h106.353c2.455 0 4.168 2.89 4.168 7.029 0 4.128-1.713 7.01-4.168 7.01zM11.25 62.573h105.492c.236-.416.607-1.449.607-3.017 0-1.586-.369-2.623-.604-3.037h-105.5c-.232.413-.596 1.449-.596 3.037.001 1.57.367 2.602.601 3.017z" />
                                            <path d="M35.502 113.492c-.292-.399-7.081-9.89-2.079-21.773 2.37-5.628.836-19.486-.09-23.938l-.676-.526-.005-.984c0-.526 0-1.922 1.321-2.467l1.236-.509.686.67c1.95-.397 5.143-.718 7.808 1.1 2.079 1.417 3.345 3.796 3.764 7.072 1.277 9.956 3.871 13.198 5.823 14.163 1.682.832 3.931.45 6.684-1.141l1.997 3.456c-3.982 2.301-7.499 2.726-10.453 1.264-4.167-2.064-6.788-7.7-8.011-17.235-.268-2.096-.959-3.534-2.053-4.28-1.189-.812-2.818-.786-4.104-.599 1.304 6.407 2.223 19.635-.248 25.504-4.135 9.821 1.563 17.785 1.621 17.864l-3.221 2.359zM58.107 127.758h-3.993v-8.283l46.43-51.514c-1.732-.606-4.613-.723-8.172 2.526-7.879 7.197-20.441 19.926-20.566 20.054l-2.842-2.803c.127-.129 12.762-12.931 20.717-20.198 6.768-6.184 12.99-3.694 15.336-1.513l1.439 1.337-48.35 53.645v6.749z" />
                                            <path d="m33.728 128-3.704-1.491 6.061-15.05c.334-.957 3.969-10.812 14.163-17.539 9.797-6.467 12.292-13.561 13.371-19.019 1.577-7.985 3.221-10.281 11.784-11.226l2.814-.311-7.74 32.64-3.885-.922 6.414-27.043c-3.959.749-4.393 2.176-5.471 7.636-1.229 6.221-4.056 14.296-15.088 21.576-9.309 6.146-12.568 15.453-12.601 15.545l-.039.105L33.728 128zM62.004 0h3.992v6.481h-3.992z" />
                                        </svg>
                                    </div>
                                    <div className="col-md">
                                        <h4 className="h5 text-dark">{t('Home.express')}</h4>
                                        <p className="mb-0">{t('Home.expressDesc')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="pb-3 pt-3" data-pg-ia-scene='{"dir":"a","s":"onEnter","l":[{"a":"zoomIn"}],"o":"-1000px"}'>
                                <div className="gx-0 gy-3 row">
                                    <div className="col-md-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="currentColor" width="4rem" height="4rem" className="me-3 text-primary">
                                            <g data-name="Group 1055" transform="translate(-17870.99 -9283.5)">
                                                <path data-name="Path 7087" d="M17916 9307v20m0-19h-40m40 18h-40m40-9h-40m0-10v20m26.26-19c1.58-3.71 3.13-7.09 3.91-8.88a2.24 2.24 0 012-1.4 8.64 8.64 0 013.63.88 8.54 8.54 0 012.86 2.4 2.24 2.24 0 01.06 2.46c-.61 1-1.53 2.63-2.62 4.51m1.18-12.22l-1.36 2.68m4.03-1.31l-1.82 3.56m-3.52-6.29l-1.82 3.56m3.48 7.39h0m-8.01-.72l.89.45m-25.85 2.93c-3.44-7.33-4.38-13.93-2-15.13 2.46-1.29 7.79 4.13 11.85 12.05m-.53 3.08c.52-2 1.29-4 2.41-5 0 0 .67 2.34 1.52 5" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                <circle data-name="Ellipse 292" cx=".85" cy=".85" r=".85" transform="rotate(-27.11 28226.34 -32434.387)" />
                                                <circle data-name="Ellipse 293" cx=".85" cy=".85" r=".85" transform="rotate(-27.11 28234.228 -32432.566)" />
                                                <circle data-name="Ellipse 294" cx=".85" cy=".85" r=".85" transform="rotate(-27.11 28239.058 -32428.123)" />
                                                <circle data-name="Ellipse 295" cx=".85" cy=".85" r=".85" transform="rotate(-27.11 28236.924 -32436.93)" />
                                                <circle data-name="Ellipse 296" cx=".85" cy=".85" r=".85" transform="rotate(-27.11 28222.312 -32427.83)" />
                                                <circle data-name="Ellipse 297" cx=".85" cy=".85" r=".85" transform="rotate(-27.11 28216.61 -32428.31)" />
                                                <circle data-name="Ellipse 298" cx=".85" cy=".85" r=".85" transform="rotate(-27.11 28229.81 -32427.759)" />
                                                <path data-name="Path 7088" d="M17904.92 9301.94h0a10.65 10.65 0 00-8.92-4.33 10.79 10.79 0 00-8.7 4" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                <path data-name="Path 7089" d="M17896 9297.61s-1.27-7-.63-7.61m2.53 0l-1.27 6.98m-3.8-6.98s.91 7 2.36 7.61" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="col-md">
                                        <h4 className="h5 text-dark">{t('Home.suivi')}</h4>
                                        <p className="mb-0">{t('Home.suiviDesc')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="pb-3 pt-3" data-pg-ia-scene='{"dir":"a","s":"onEnter","l":[{"a":"zoomIn"}],"o":"-1000px"}'>
                                <div className="gx-0 gy-3 row">
                                    <div className="col-md-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="currentColor" width="4rem" height="4rem" className="me-3 text-primary">
                                            <path d="m29.59 13.21-3.9-3.85A1.31 1.31 0 0 0 24.8 9H19V6.38A1.3 1.3 0 0 0 17.81 5H3.21A1.3 1.3 0 0 0 2 6.38v14.24A1.3 1.3 0 0 0 3.21 22H4a2.74 2.74 0 0 0 0 .5 3.5 3.5 0 0 0 7 0 2.74 2.74 0 0 0 0-.5h10a2.74 2.74 0 0 0 0 .5 3.5 3.5 0 0 0 7 0 2.74 2.74 0 0 0 0-.5h.74A1.35 1.35 0 0 0 30 20.61v-6.4a1.4 1.4 0 0 0-.41-1zM7.5 24A1.5 1.5 0 0 1 6 22.5a1.39 1.39 0 0 1 .09-.5 1.4 1.4 0 0 1 .69-.8 1.64 1.64 0 0 1 .33-.14A1.58 1.58 0 0 1 7.5 21a1.5 1.5 0 0 1 0 3zm2.44-4a3.48 3.48 0 0 0-4.88 0H4V7h13v13zM26 22.5a1.5 1.5 0 1 1-1.5-1.5 1.27 1.27 0 0 1 .39.06 1.46 1.46 0 0 1 1 .94 1.39 1.39 0 0 1 .11.5zm2-2.5h-1.06a3.48 3.48 0 0 0-4.87 0H19v-9h5.57L28 14.53z" />
                                        </svg>
                                    </div>
                                    <div className="col-md">
                                        <h4 className="h5 text-dark">{t('Home.serviceClient')}</h4>
                                        <p className="mb-0">{t('Home.serviceClientDesc')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5 pt-5 text-center text-white">
                <div className="container pb-4 pt-4">
                    <div className="col-lg-9 mb-5 me-auto ms-auto">
                        <h2 className="fw-bold h6 mb-3 text-muted text-uppercase">{t('Home.satisfaction')}</h2>
                        <h3 className="display-5 fw-bold text-info">&nbsp;{t('Home.satisfactionAv')}</h3>
                        <p className="d-none fw-light">{t('Home.satisfactionDesc')}</p>
                    </div>
                    <div className="justify-content-center row">
                        <div className="col-md-4 col-xl-4 pb-3 pt-3" data-pg-ia-scene="{&quot;dir&quot;:&quot;a&quot;,&quot;s&quot;:&quot;onEnter&quot;,&quot;l&quot;:[{&quot;a&quot;:&quot;zoomIn&quot;}],&quot;o&quot;:&quot;-1500px&quot;}">
                            <div>
                                <h2 className="align-items-center d-flex fw-bold h4 justify-content-center mb-3 text-white"><span className="display-4 fw-bold me-2 text-secondary">1.</span><span className="text-primary">{t('Home.securityTitle')}</span></h2>
                                <p className="text-body">{t('Home.securityDesc')}</p>
                                <a href="#" className="d-none link-primary">{t('Globals.learnMore')}</a>
                            </div>
                        </div>
                        <div className="col-md-4 col-xl-4 pb-3 pt-3" data-pg-ia-scene="{&quot;dir&quot;:&quot;a&quot;,&quot;s&quot;:&quot;onEnter&quot;,&quot;l&quot;:[{&quot;a&quot;:&quot;zoomIn&quot;}],&quot;o&quot;:&quot;-1500px&quot;}">
                            <div>
                                <h2 className="align-items-center d-flex fw-bold h4 justify-content-center mb-3 text-white"><span className="display-4 fw-bold me-2 text-secondary">2.</span><span className="text-primary">{t('Home.fiabiliteTitle')}</span></h2>
                                <p className="text-body">{t('Home.fiabiliteDesc')}</p>
                                <a href="#" className="d-none link-primary">{t('Globals.learnMore')}</a>
                            </div>
                        </div>
                        <div className="col-md-4 col-xl-4 pb-3 pt-3" data-pg-ia-scene="{&quot;dir&quot;:&quot;a&quot;,&quot;s&quot;:&quot;onEnter&quot;,&quot;l&quot;:[{&quot;a&quot;:&quot;zoomIn&quot;}],&quot;o&quot;:&quot;-1500px&quot;}">
                            <div>
                                <h2 className="align-items-center d-flex fw-bold h4 justify-content-center mb-3 text-white"><span className="display-4 fw-bold me-2 text-secondary">3.</span><span className="text-primary">{t('Home.rapideTitle')}</span></h2>
                                <p className="text-body">{t('Home.rapidedesc')}</p>
                                <a href="#" className="d-none link-primary">{t('Globals.learnMore')}</a>
                            </div>
                        </div>
                    </div>
                    <img src="https://images.unsplash.com/photo-1628199856702-e33fc268e783?ixid=M3wyMDkyMnwwfDF8c2VhcmNofDI2fHxkZWxpdmVyfGVufDB8fHx8MTY5Njk1NDQzM3ww&amp;ixlib=rb-4.0.3q=85&amp;fm=jpg&amp;crop=faces&amp;cs=srgb&amp;w=1290&amp;h=537&amp;fit=crop" className="img-fluid w-100" data-pg-ia-scene="{&quot;dir&quot;:&quot;a&quot;,&quot;s&quot;:&quot;onEnter&quot;,&quot;l&quot;:[{&quot;a&quot;:&quot;zoomIn&quot;}],&quot;o&quot;:&quot;-1500px&quot;}" style={{ translate: "none", rotate: "none", scale: "none", transform: "translate3d(0px, 0px, 0px) scale(0.9851, 0.9851)", opacity: 1, visibility: "inherit" }} />
                </div>
            </section>
            <section className="d-none pb-5 pt-5" data-pgc-define="reviews" data-pgc-define-name="Reviews">
                <div className="col-lg-6 me-auto ms-auto text-center">
                    <h2 className="h6 mb-1 text-secondary">Leden Go</h2>
                    <h3 className="fw-bold h2 mb-3 text-primary">{t('Home.satisfaits')}</h3>
                    <div style={{ width: "25%", height: "5px" }} className="bg-secondary me-auto ms-auto" data-pgc-define="divider"></div>
                </div>
                <div className="owl-carousel owl-theme mt-5">
                    <div className="owl-item">
                        <div className="card">
                            <div className="img-card">
                            </div>
                            <div className="testimonial mt-4 mb-2">
                                {t('Home.testimonials.1')}
                            </div>
                            <div className="name">
                                Xavier C.
                            </div>
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="card">
                            <div className="img-card">
                                <img src="https://images.pexels.com/photos/1024311/pexels-photo-1024311.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt="" className="d-none" />
                            </div>
                            <div className="testimonial mt-4 mb-2">
                                {t('Home.testimonials.2')}
                            </div>
                            <div className="name">
                                Lisa T.
                            </div>
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="card">
                            <div className="img-card">
                            </div>
                            <div className="testimonial mt-4 mb-2">{t('Home.testimonials.3')}</div>
                            <div className="name">
                                Elise M.
                            </div>
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="card">
                            <div className="img-card">
                            </div>
                            <div className="testimonial mt-4 mb-2">
                            {t('Home.testimonials.4')}
                            </div>
                            <div className="name">
                                Daniel G.
                            </div>
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="card">
                            <div className="img-card">
                            </div>
                            <div className="testimonial mt-4 mb-2">
                            {t('Home.testimonials.5')}
                            </div>
                            <div className="name">
                                Marcel K.
                            </div>
                        </div>
                    </div>
                    <div className="owl-item">
                        <div className="card">
                            <div className="img-card">
                            </div>
                            <div className="testimonial mt-4 mb-2">
                            {t('Home.testimonials.6')}
                            </div>
                            <div className="name">
                                Bryan Z.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-secondary d-none pb-5 position-relative text-center text-white-50" id="process" style={{ zIndex: "0 !important" }}>
                <div className="container pb-5 pt-5">
                    <div className="align-items-center bg-light d-none mb-3 pt-3 row">
                        <div className="col-lg-7">
                            <h2 className="h6 mb-1 text-info">{t('Home.processTitle')}</h2>
                            <h3 className="fw-bold h2 mb-1 text-body">{t('Home.processSub')}</h3>
                            <p className="fw-light text-dark">{t('Home.processDesc')}</p>
                        </div>
                        <div className="col-lg-auto ms-auto"> <a href="#" className="btn btn-primary">{t('Home.Globals.readNore')}</a>
                        </div>
                    </div>
                    <section className="pb-5 pt-5 text-black-50">
                        <div className="container">
                            <div className="align-items-center mb-3 row">
                                <div className="col-xl-3 pb-4 pt-4">
                                    <h2 className="h6 text-secondary">{t('Home.processTitle')}</h2>
                                    <h3 className="h2 mb-4 text-white">{t('Home.processDesc')}</h3>
                                </div>
                                <div className="col-xl-9 ms-auto">
                                    <div className="pb-lg-1 row">
                                        <div className="col-lg-6 col-md-6 pb-3 pt-3" data-pg-ia="{&quot;l&quot;:[{&quot;trg&quot;:&quot;mouseenter&quot;,&quot;a&quot;:&quot;bounceIn&quot;}]}">
                                            <div className="border h-100 pb-5 pe-4 ps-4 pt-5 text-white" data-pg-ia-scene='{"dir":"a","s":"onEnter","l":[{"a":"fadeInLeft"}],"o":"-1000px"}'>
                                                <div className="ps-3 pe-3"><span className="fa-3x fa-stack"><i className="fas fa-circle fa-stack-2x"></i><i className="fa-shopping-cart fa-inverse fa-stack-1x fas text-primary"></i></span>
                                                    <h2 className="align-items-center d-flex fw-bold h4 justify-content-center mb-1 text-white"><span className="fw-bold h2 me-2 text-secondary">1.</span><span className="fs-5 pb-lg-1">{t('Home.step.1.title')}</span></h2>
                                                    <p className="mb-0">{t('Home.step.1.desc')}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 pb-3 pt-3" data-pg-ia="{&quot;l&quot;:[{&quot;trg&quot;:&quot;mouseenter&quot;,&quot;a&quot;:&quot;bounceIn&quot;}]}">
                                            <div className="border h-100 pb-5 pe-4 ps-4 pt-5 text-white" data-pg-ia-scene='{"dir":"a","dbg":"true","s":"onEnter","l":[{"a":"fadeInRight"}],"o":"-1000px"}'>
                                                <div className="ps-3 pe-3"><span className="fa-3x fa-stack"><i className="fas fa-circle fa-stack-2x"></i><i className="fa-cogs fa-inverse fa-stack-1x fas text-primary"></i></span>
                                                    <h2 className="align-items-center d-flex fw-bold h4 justify-content-center mb-1 text-white"><span className="fw-bold h2 me-2 text-secondary">2.</span><span className="fs-5 pb-lg-1">{t('Home.step.2.title')}</span></h2>
                                                    <p className="mb-0">{t('Home.step.2.desc')}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 pb-3 pt-3" data-pg-ia="{&quot;l&quot;:[{&quot;trg&quot;:&quot;mouseenter&quot;,&quot;a&quot;:&quot;bounceIn&quot;}]}">
                                            <div className="border h-100 pb-5 pe-4 ps-4 pt-5 text-white" data-pg-ia-scene='{"dir":"a","s":"onEnter","l":[{"a":"fadeInLeft"}],"o":"-1000px"}'>
                                                <div className="ps-3 pe-3"><span className="fa-3x fa-stack"><i className="fas fa-circle fa-stack-2x"></i><i className="fa-box fa-inverse fa-stack-1x fas text-primary"></i></span>
                                                    <h2 className="align-items-center d-flex fw-bold h4 justify-content-center mb-1 text-white"><span className="fw-bold h2 me-2 text-secondary">3.</span><span className="fs-5 pb-lg-1">{t('Home.step.3.title')}</span></h2>
                                                    <p className="mb-0">{t('Home.step.3.desc')}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 pb-3 pt-3" data-pg-ia="{&quot;l&quot;:[{&quot;trg&quot;:&quot;mouseenter&quot;,&quot;a&quot;:&quot;bounceIn&quot;}]}">
                                            <div className="border h-100 pb-5 pe-4 ps-4 pt-5 text-white" data-pg-ia-scene="{&quot;dir&quot;:&quot;a&quot;,&quot;dbg&quot;:&quot;true&quot;,&quot;s&quot;:&quot;onEnter&quot;,&quot;o&quot;:&quot;-800px&quot;,&quot;l&quot;:[{&quot;a&quot;:&quot;fadeInRight&quot;}]}">
                                                <div className="ps-3 pe-3"><span className="fa-3x fa-stack"><i className="fas fa-circle fa-stack-2x"></i><i className="fa-map-marker fa-inverse fa-stack-1x fas text-primary"></i></span>
                                                    <h2 className="align-items-center d-flex fw-bold h4 justify-content-center mb-1 text-white"><span className="fw-bold h2 me-2 text-secondary">4.</span><span className="fs-5 pb-lg-1">{t('Home.step.4.title')}</span></h2>
                                                    <p className="mb-0">{t('Home.step.4.desc')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="align-items-center mb-3 row">
                        <div className="col-lg-6 d-none order-lg-2">
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="878.64" height="552.215" viewBox="0 0 878.64 552.215" className="col-10 d-block img-fluid mb-4 mt-4 mx-auto">
                                <path d="M837.863 552.215H95.229a24.9 24.9 0 01-24.9-24.901q403.806-46.93 792.435 0a24.9 24.9 0 01-24.901 24.9z" fill="#ccc" />
                                <path d="M862.764 528.779l-792.436-1.465 91.841-154.532.44-.733V31.478A31.475 31.475 0 01194.085 0h540.526a31.475 31.475 0 0131.478 31.478v342.915z" fill="#e6e6e6" />
                                <path d="M193.368 21.971a10.265 10.265 0 00-10.253 10.254v310.529a10.265 10.265 0 0010.253 10.253h546.356a10.266 10.266 0 0010.253-10.253V32.224a10.265 10.265 0 00-10.253-10.253z" fill="#fff" />
                                <path d="M196.959 394.02a4.406 4.406 0 00-3.992 2.558l-28.3 61.52a4.394 4.394 0 003.992 6.231h595.112a4.394 4.394 0 003.929-6.36l-30.76-61.52a4.372 4.372 0 00-3.93-2.428z" fill="#ccc" />
                                <circle cx="464.349" cy="10.253" r="6.591" fill="#fff" />
                                <path d="M405.854 476.047a4.401 4.401 0 00-4.242 3.252l-7.099 26.365a4.394 4.394 0 004.243 5.537h134.997a4.393 4.393 0 004.152-5.831l-9.127-26.366a4.397 4.397 0 00-4.152-2.957zM766.09 366.922v5.86H162.169l.454-.733v-5.127H766.09zM691.547 380.344a1.072 1.072 0 00-1.033.792l-1.728 6.42a1.07 1.07 0 001.033 1.349h32.874a1.07 1.07 0 001.01-1.42l-2.222-6.421a1.07 1.07 0 00-1.011-.72zM643.547 380.344a1.072 1.072 0 00-1.033.792l-1.728 6.42a1.07 1.07 0 001.033 1.349h32.874a1.07 1.07 0 001.01-1.42l-2.222-6.421a1.07 1.07 0 00-1.011-.72z" fill="#ccc" />
                                <path d="M504.481 421.14h2.577L501.905 76.75h-2.577l.103 6.87-57.747.001.103-6.87h-2.576l-5.153 344.388h2.576l.334-22.33h67.18zm-3.713-248.2h-60.42l.63-42.083h59.16zm.038 2.576l.63 42.082H439.68l.63-42.082zm.668 44.659l.63 42.082h-63.092l.63-42.082zm.669 44.659l.63 42.082h-64.43l.63-42.082zm.668 44.659l.63 42.082h-65.766l.63-42.082zM499.47 86.198l.63 42.083h-59.084l.63-42.083zm-62.463 310.036l.63-42.083h65.842l.63 42.083z" fill="#3f3d56" />
                                <circle cx="457.794" cy="153.358" r="42.012" fill="#2f2e41" />
                                <path fill="#2f2e41" d="M469.947 184.665l12.56-2.274 4.073 22.502-12.56 2.273zM444.83 189.212l12.558-2.274 4.074 22.502-12.56 2.273z" />
                                <ellipse cx="613.672" cy="387.298" rx="10.636" ry="3.989" transform="rotate(-40.261 296.132 519.53)" fill="#2f2e41" />
                                <ellipse cx="638.267" cy="382.846" rx="10.636" ry="3.989" transform="rotate(-40.261 320.726 515.077)" fill="#2f2e41" />
                                <circle cx="454.853" cy="143.081" r="14.359" fill="#fff" />
                                <circle cx="448.952" cy="137.934" r="4.786" fill="#3f3d56" />
                                <path d="M490.936 107.653c.632-15.554-12.773-28.728-29.94-29.425s-31.597 11.346-32.23 26.9 11.303 19.087 28.47 19.784 33.068-1.706 33.7-17.26z" fill="#007bff" />
                                <ellipse cx="579.682" cy="319.596" rx="6.594" ry="21.006" transform="rotate(-77.09 390.211 333.489)" fill="#2f2e41" />
                                <ellipse cx="662.15" cy="333.311" rx="6.594" ry="21.006" transform="rotate(-77.09 472.68 347.203)" fill="#2f2e41" />
                                <path d="M469.333 169.914a9.572 9.572 0 11-18.835 3.429l-.004-.018c-.941-5.203 3.08-7.043 8.283-7.985s9.614-.628 10.556 4.574z" fill="#fff" />
                                <ellipse cx="469.866" cy="467.526" rx="21.534" ry="6.76" transform="rotate(-69.082 263.212 497.296)" fill="#2f2e41" />
                                <circle cx="430.059" cy="500.048" r="43.067" transform="rotate(-80.783 247.526 507.53)" fill="#2f2e41" />
                                <path fill="#2f2e41" d="M249.753 359.955h13.084v23.442h-13.084zM275.921 359.955h13.084v23.442h-13.084z" />
                                <ellipse cx="260.656" cy="383.67" rx="10.903" ry="4.089" fill="#2f2e41" />
                                <ellipse cx="286.824" cy="383.125" rx="10.903" ry="4.089" fill="#2f2e41" />
                                <path d="M227.602 286.104c-3.477-15.573 7.639-31.31 24.829-35.149s33.944 5.676 37.421 21.25-7.915 21.317-25.105 25.155-33.667 4.318-37.145-11.256z" fill="#007bff" />
                                <ellipse cx="381.55" cy="480.065" rx="6.76" ry="21.534" transform="rotate(-64.626 163.743 520.14)" fill="#2f2e41" />
                                <circle cx="82.901" cy="369.763" r="43.067" fill="#2f2e41" />
                                <path fill="#2f2e41" d="M63.275 403.563h13.084v23.442H63.275zM89.443 403.563h13.084v23.442H89.443z" />
                                <ellipse cx="74.178" cy="427.277" rx="10.903" ry="4.089" fill="#2f2e41" />
                                <ellipse cx="100.346" cy="426.732" rx="10.903" ry="4.089" fill="#2f2e41" />
                                <circle cx="83.991" cy="358.86" r="14.719" fill="#fff" />
                                <circle cx="83.991" cy="358.86" r="4.906" fill="#3f3d56" />
                                <path d="M41.124 329.712c-3.477-15.574 7.639-31.31 24.829-35.149s33.944 5.675 37.421 21.249-7.915 21.318-25.105 25.156-33.667 4.318-37.145-11.256z" fill="#e6e6e6" />
                                <ellipse cx="268.669" cy="387.383" rx="21.534" ry="6.76" transform="rotate(-69.082 62.015 417.153)" fill="#2f2e41" />
                                <circle cx="228.861" cy="419.905" r="43.067" transform="rotate(-80.783 46.328 427.387)" fill="#2f2e41" />
                                <path fill="#2f2e41" d="M48.556 279.812H61.64v23.442H48.556zM74.723 279.812h13.084v23.442H74.723z" />
                                <ellipse cx="59.459" cy="303.527" rx="10.903" ry="4.089" fill="#2f2e41" />
                                <ellipse cx="85.627" cy="302.982" rx="10.903" ry="4.089" fill="#2f2e41" />
                                <circle cx="69.272" cy="235.11" r="14.719" fill="#fff" />
                                <circle cx="69.272" cy="235.11" r="4.906" fill="#3f3d56" />
                                <path d="M26.405 205.961c-3.478-15.573 7.639-31.31 24.828-35.149s33.945 5.676 37.422 21.25-7.915 21.317-25.105 25.155-33.668 4.318-37.145-11.256z" fill="#007bff" />
                                <ellipse cx="180.353" cy="399.922" rx="6.76" ry="21.534" transform="rotate(-64.626 -37.454 439.998)" fill="#2f2e41" />
                                <path d="M47.027 261.55c0 4.215 10.853 12.538 22.897 12.538s23.335-11.867 23.335-16.082-11.292.818-23.335.818-22.897-1.49-22.897 2.726z" fill="#fff" />
                                <path d="M110.524 81.569a3.003 3.003 0 00-3 3v124a3.003 3.003 0 003 3h287a3.003 3.003 0 003-3v-124a3.003 3.003 0 00-3-3z" fill="#007bff" />
                                <circle cx="188.018" cy="146.569" r="29.121" fill="#fff" />
                                <path data-name="Path 40" d="M253.744 142.694a3.405 3.405 0 000 6.81h91.945a3.405 3.405 0 10.112-6.81h-.112zM253.744 122.967a3.405 3.405 0 000 6.81h45.915a3.405 3.405 0 10.112-6.81h-.112zM253.744 163.36a3.405 3.405 0 000 6.81h91.945a3.405 3.405 0 10.112-6.81h-.112z" fill="#fff" />
                                <ellipse cx="853.282" cy="546.503" rx="6.76" ry="21.534" transform="rotate(-20.918 301.938 894.773)" fill="#2f2e41" />
                                <circle cx="884.09" cy="588.025" r="43.067" transform="rotate(-9.217 -274.834 1497.71)" fill="#2f2e41" />
                                <path fill="#2f2e41" d="M743.035 447.932h-13.083l2.56 28.073 10.546-2.865-.023-25.208zM714.512 448.005h-13.084l2.56 28.073 10.07-2.938.454-25.135z" />
                                <ellipse cx="863.998" cy="647.212" rx="10.903" ry="4.089" transform="rotate(-4.182 -1597.917 2760.883)" fill="#2f2e41" />
                                <circle cx="722.319" cy="403.229" r="14.719" fill="#fff" />
                                <circle cx="722.319" cy="403.229" r="4.906" fill="#3f3d56" />
                                <ellipse cx="929.598" cy="558.041" rx="21.534" ry="6.76" transform="rotate(-53.55 676.946 630.314)" fill="#2f2e41" />
                                <path d="M702.397 436.339c0-3.474 8.945-10.334 18.87-10.334s19.233 9.78 19.233 13.254-9.306-.674-19.232-.674-18.871 1.228-18.871-2.246z" fill="#fff" />
                                <path d="M844.177 378.542L608.43 345.86a7.008 7.008 0 01-5.972-7.895l26.502-191.172a7.008 7.008 0 017.894-5.973l235.746 32.682a7.008 7.008 0 015.972 7.894L852.072 372.57a7.008 7.008 0 01-7.895 5.973z" fill="#007bff" />
                                <path d="M759.722 318.88l-120.354-16.685a2.477 2.477 0 01-.445-.098l70.59-90.706a4.024 4.024 0 016.938.961l29.03 71.463 1.39 3.42z" fill="#fff" />
                                <path opacity=".2" style={{ isolation: "isolate" }} d="M759.722 318.879l-41.092-5.696 24.234-26.504 1.745-1.909.872-.955 1.391 3.42 12.85 31.644z" />
                                <path d="M826.735 328.17l-104.097-14.431 24.234-26.504 1.744-1.91 31.58-34.54a5.37 5.37 0 017.96.623 4.796 4.796 0 01.313.524z" fill="#fff" />
                                <circle cx="761.186" cy="224.184" r="18" fill="#fff" />
                                <path fill="#3f3d56" d="M640.876 155.517l21.792 3.022-3.021 21.791-21.792-3.02z" />
                                <path d="M765.187 374.08c3.477-15.573-7.64-31.31-24.83-35.148s-33.943 5.675-37.42 21.249 7.914 21.317 25.104 25.156 33.668 4.317 37.146-11.256z" fill="#f2f2f2" />
                                <ellipse cx="892.69" cy="647.142" rx="10.903" ry="4.089" transform="rotate(-4.182 -1569.224 2760.814)" fill="#2f2e41" />
                            </svg>
                        </div>
                        <div className="d-none ms-auto">
                            <div className="row row_step">
                                <div className="col-sm-6 pb-3 pt-3">
                                    <div className="pb-3 pt-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40" fill="currentColor" className="mb-3 text-white">
                                            <path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm13.464 12.536L20 12l-3.536-3.536L15.05 9.88 17.172 12l-2.122 2.121 1.414 1.415zM6.828 12L8.95 9.879 7.536 8.464 4 12l3.536 3.536L8.95 14.12 6.828 12zm4.416 5l3.64-10h-2.128l-3.64 10h2.128z"></path>
                                        </svg>
                                        <h4 className="fw-bold h5 text-info">1. {t('Home.step.1.title')}</h4>
                                        <p className="text-white">{t('Home.step.1.desc')}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 pb-3 pt-3">
                                    <div className="pb-3 pt-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40" fill="currentColor" className="mb-3 text-primary">
                                            <path d="M5.636 12.707l1.828 1.829L8.88 13.12 7.05 11.293l1.414-1.414 1.829 1.828 1.414-1.414L9.88 8.464l1.414-1.414L13.12 8.88l1.415-1.415-1.829-1.828 2.829-2.828a1 1 0 0 1 1.414 0l4.242 4.242a1 1 0 0 1 0 1.414L8.464 21.192a1 1 0 0 1-1.414 0L2.808 16.95a1 1 0 0 1 0-1.414l2.828-2.829zm8.485 5.656l4.243-4.242L21 16.757V21h-4.242l-2.637-2.637zM5.636 9.878L2.807 7.05a1 1 0 0 1 0-1.415l2.829-2.828a1 1 0 0 1 1.414 0L9.88 5.635 5.636 9.878z"></path>
                                        </svg>
                                        <h4 className="fw-bold h5 text-info">2. {t('Home.step.2.title')}</h4>
                                        <p className="text-white">{t('Home.step.2.desc')}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 pb-3 pt-3">
                                    <div className="pb-3 pt-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40" fill="currentColor" className="mb-3 text-primary">
                                            <path d="M15 21h-2v-3h-2v3H9v-2H7v2H4a1 1 0 0 1-1-1v-3h2v-2H3v-2h3v-2H3V9h2V7H3V4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v9h9a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-3v-2h-2v2z"></path>
                                        </svg>
                                        <h4 className="fw-bold h5 text-info">3. {t('Home.step.3.title')}</h4>
                                        <p className="text-white">{t('Home.step.3.desc')}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 pb-3 pt-3">
                                    <div className="pb-3 pt-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40" fill="currentColor" className="mb-3 text-primary">
                                            <path d="M8 5h3v9H8v3H6v-3H3V5h3V2h2v3zm10 5h3v9h-3v3h-2v-3h-3v-9h3V7h2v3z"></path>
                                        </svg>
                                        <h4 className="fw-bold h5 text-info">4. {t('Home.step.4.title')}</h4>
                                        <p className="text-white">{t('Home.step.4.desc')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-secondary pb-5 position-relative text-center text-white-50" id="process" style={{ zIndex: "0 !important" }}>
                <div className="container pb-5 pt-5">
                    <div className="align-items-center bg-light d-none mb-3 pt-3 row">
                        <div className="col-lg-7">
                            <h2 className="h6 mb-1 text-info">{t('Home.processTitle')}</h2>
                            <h3 className="fw-bold h2 mb-1 text-body">{t('Home.processSub')}</h3>
                            <p className="fw-light text-dark">{t('Home.processdesc')}</p>
                        </div>
                        <div className="col-lg-auto ms-auto"> <a href="#" className="btn btn-primary">{t('Globals.readMore')}</a>
                        </div>
                    </div>
                    <section className="d-flex justify-content-center pb-5 pt-5 text-black-50">
                        <div className="align-items-center container d-flex row">
                            <div className="col-lg-5 order-lg-1 py-3" data-pg-ia-scene='{"dir":"a","s":"onEnter","l":[{"a":"slideInLeft"}],"o":"-1500px"}'>
                                <h3 className="bg-white fw-bold h2 mb-1 text-secondary">{t('Globals.contactUs')}</h3>
                                <p className="fw-normal mb-4 text-white">{t('Home.contactDesc')}</p>
                            </div>
                            <div className="col-12 col-lg-6 ms-auto order-lg-2 py-3 text-center" data-pg-ia-scene='{"dir":"a","s":"onEnter","l":[{"a":"zoomIn"}],"o":"-1500px"}'>
                                <div className="container pb-5 pt-5">
                                    <div className="bg-white p-4 p-lg-5 shadow">
                                        <h2 className="mb-4 text-primary">{t('Home.form.title')}</h2>
                                        <div className="mb-3">
                                            <input type="text" className="form-control pb-3 pt-3 rounded-0" placeholder={t('Home.form.name')} id="name" />
                                        </div>
                                        <div className="mb-3">
                                            <input type="email" className="form-control pb-3 pt-3 rounded-0" placeholder={t('Home.form.email')} id="email" />
                                        </div>
                                        <div className="mb-3">
                                            <textarea className="form-control rounded-0" rows="6" placeholder={t('Home.form.message')} id="message"></textarea>
                                        </div>
                                        <button type="button" className="btn btn-secondary pb-3 pt-3 rounded-0 text-uppercase w-100" id="submitButton" > <span id="spinner" className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ display: "none" }}></span> <span id="text_span"> {t('Home.form.button')} </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="align-items-center mb-3 row">
                        <div className="col-lg-6 d-none order-lg-2">
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="878.64" height="552.215" viewBox="0 0 878.64 552.215" className="col-10 d-block img-fluid mb-4 mt-4 mx-auto">
                                <path d="M837.863 552.215H95.229a24.9 24.9 0 01-24.9-24.901q403.806-46.93 792.435 0a24.9 24.9 0 01-24.901 24.9z" fill="#ccc" />
                                <path d="M862.764 528.779l-792.436-1.465 91.841-154.532.44-.733V31.478A31.475 31.475 0 01194.085 0h540.526a31.475 31.475 0 0131.478 31.478v342.915z" fill="#e6e6e6" />
                                <path d="M193.368 21.971a10.265 10.265 0 00-10.253 10.254v310.529a10.265 10.265 0 0010.253 10.253h546.356a10.266 10.266 0 0010.253-10.253V32.224a10.265 10.265 0 00-10.253-10.253z" fill="#fff" />
                                <path d="M196.959 394.02a4.406 4.406 0 00-3.992 2.558l-28.3 61.52a4.394 4.394 0 003.992 6.231h595.112a4.394 4.394 0 003.929-6.36l-30.76-61.52a4.372 4.372 0 00-3.93-2.428z" fill="#ccc" />
                                <circle cx="464.349" cy="10.253" r="6.591" fill="#fff" />
                                <path d="M405.854 476.047a4.401 4.401 0 00-4.242 3.252l-7.099 26.365a4.394 4.394 0 004.243 5.537h134.997a4.393 4.393 0 004.152-5.831l-9.127-26.366a4.397 4.397 0 00-4.152-2.957zM766.09 366.922v5.86H162.169l.454-.733v-5.127H766.09zM691.547 380.344a1.072 1.072 0 00-1.033.792l-1.728 6.42a1.07 1.07 0 001.033 1.349h32.874a1.07 1.07 0 001.01-1.42l-2.222-6.421a1.07 1.07 0 00-1.011-.72zM643.547 380.344a1.072 1.072 0 00-1.033.792l-1.728 6.42a1.07 1.07 0 001.033 1.349h32.874a1.07 1.07 0 001.01-1.42l-2.222-6.421a1.07 1.07 0 00-1.011-.72z" fill="#ccc" />
                                <path d="M504.481 421.14h2.577L501.905 76.75h-2.577l.103 6.87-57.747.001.103-6.87h-2.576l-5.153 344.388h2.576l.334-22.33h67.18zm-3.713-248.2h-60.42l.63-42.083h59.16zm.038 2.576l.63 42.082H439.68l.63-42.082zm.668 44.659l.63 42.082h-63.092l.63-42.082zm.669 44.659l.63 42.082h-64.43l.63-42.082zm.668 44.659l.63 42.082h-65.766l.63-42.082zM499.47 86.198l.63 42.083h-59.084l.63-42.083zm-62.463 310.036l.63-42.083h65.842l.63 42.083z" fill="#3f3d56" />
                                <circle cx="457.794" cy="153.358" r="42.012" fill="#2f2e41" />
                                <path fill="#2f2e41" d="M469.947 184.665l12.56-2.274 4.073 22.502-12.56 2.273zM444.83 189.212l12.558-2.274 4.074 22.502-12.56 2.273z" />
                                <ellipse cx="613.672" cy="387.298" rx="10.636" ry="3.989" transform="rotate(-40.261 296.132 519.53)" fill="#2f2e41" />
                                <ellipse cx="638.267" cy="382.846" rx="10.636" ry="3.989" transform="rotate(-40.261 320.726 515.077)" fill="#2f2e41" />
                                <circle cx="454.853" cy="143.081" r="14.359" fill="#fff" />
                                <circle cx="448.952" cy="137.934" r="4.786" fill="#3f3d56" />
                                <path d="M490.936 107.653c.632-15.554-12.773-28.728-29.94-29.425s-31.597 11.346-32.23 26.9 11.303 19.087 28.47 19.784 33.068-1.706 33.7-17.26z" fill="#007bff" />
                                <ellipse cx="579.682" cy="319.596" rx="6.594" ry="21.006" transform="rotate(-77.09 390.211 333.489)" fill="#2f2e41" />
                                <ellipse cx="662.15" cy="333.311" rx="6.594" ry="21.006" transform="rotate(-77.09 472.68 347.203)" fill="#2f2e41" />
                                <path d="M469.333 169.914a9.572 9.572 0 11-18.835 3.429l-.004-.018c-.941-5.203 3.08-7.043 8.283-7.985s9.614-.628 10.556 4.574z" fill="#fff" />
                                <ellipse cx="469.866" cy="467.526" rx="21.534" ry="6.76" transform="rotate(-69.082 263.212 497.296)" fill="#2f2e41" />
                                <circle cx="430.059" cy="500.048" r="43.067" transform="rotate(-80.783 247.526 507.53)" fill="#2f2e41" />
                                <path fill="#2f2e41" d="M249.753 359.955h13.084v23.442h-13.084zM275.921 359.955h13.084v23.442h-13.084z" />
                                <ellipse cx="260.656" cy="383.67" rx="10.903" ry="4.089" fill="#2f2e41" />
                                <ellipse cx="286.824" cy="383.125" rx="10.903" ry="4.089" fill="#2f2e41" />
                                <path d="M227.602 286.104c-3.477-15.573 7.639-31.31 24.829-35.149s33.944 5.676 37.421 21.25-7.915 21.317-25.105 25.155-33.667 4.318-37.145-11.256z" fill="#007bff" />
                                <ellipse cx="381.55" cy="480.065" rx="6.76" ry="21.534" transform="rotate(-64.626 163.743 520.14)" fill="#2f2e41" />
                                <circle cx="82.901" cy="369.763" r="43.067" fill="#2f2e41" />
                                <path fill="#2f2e41" d="M63.275 403.563h13.084v23.442H63.275zM89.443 403.563h13.084v23.442H89.443z" />
                                <ellipse cx="74.178" cy="427.277" rx="10.903" ry="4.089" fill="#2f2e41" />
                                <ellipse cx="100.346" cy="426.732" rx="10.903" ry="4.089" fill="#2f2e41" />
                                <circle cx="83.991" cy="358.86" r="14.719" fill="#fff" />
                                <circle cx="83.991" cy="358.86" r="4.906" fill="#3f3d56" />
                                <path d="M41.124 329.712c-3.477-15.574 7.639-31.31 24.829-35.149s33.944 5.675 37.421 21.249-7.915 21.318-25.105 25.156-33.667 4.318-37.145-11.256z" fill="#e6e6e6" />
                                <ellipse cx="268.669" cy="387.383" rx="21.534" ry="6.76" transform="rotate(-69.082 62.015 417.153)" fill="#2f2e41" />
                                <circle cx="228.861" cy="419.905" r="43.067" transform="rotate(-80.783 46.328 427.387)" fill="#2f2e41" />
                                <path fill="#2f2e41" d="M48.556 279.812H61.64v23.442H48.556zM74.723 279.812h13.084v23.442H74.723z" />
                                <ellipse cx="59.459" cy="303.527" rx="10.903" ry="4.089" fill="#2f2e41" />
                                <ellipse cx="85.627" cy="302.982" rx="10.903" ry="4.089" fill="#2f2e41" />
                                <circle cx="69.272" cy="235.11" r="14.719" fill="#fff" />
                                <circle cx="69.272" cy="235.11" r="4.906" fill="#3f3d56" />
                                <path d="M26.405 205.961c-3.478-15.573 7.639-31.31 24.828-35.149s33.945 5.676 37.422 21.25-7.915 21.317-25.105 25.155-33.668 4.318-37.145-11.256z" fill="#007bff" />
                                <ellipse cx="180.353" cy="399.922" rx="6.76" ry="21.534" transform="rotate(-64.626 -37.454 439.998)" fill="#2f2e41" />
                                <path d="M47.027 261.55c0 4.215 10.853 12.538 22.897 12.538s23.335-11.867 23.335-16.082-11.292.818-23.335.818-22.897-1.49-22.897 2.726z" fill="#fff" />
                                <path d="M110.524 81.569a3.003 3.003 0 00-3 3v124a3.003 3.003 0 003 3h287a3.003 3.003 0 003-3v-124a3.003 3.003 0 00-3-3z" fill="#007bff" />
                                <circle cx="188.018" cy="146.569" r="29.121" fill="#fff" />
                                <path data-name="Path 40" d="M253.744 142.694a3.405 3.405 0 000 6.81h91.945a3.405 3.405 0 10.112-6.81h-.112zM253.744 122.967a3.405 3.405 0 000 6.81h45.915a3.405 3.405 0 10.112-6.81h-.112zM253.744 163.36a3.405 3.405 0 000 6.81h91.945a3.405 3.405 0 10.112-6.81h-.112z" fill="#fff" />
                                <ellipse cx="853.282" cy="546.503" rx="6.76" ry="21.534" transform="rotate(-20.918 301.938 894.773)" fill="#2f2e41" />
                                <circle cx="884.09" cy="588.025" r="43.067" transform="rotate(-9.217 -274.834 1497.71)" fill="#2f2e41" />
                                <path fill="#2f2e41" d="M743.035 447.932h-13.083l2.56 28.073 10.546-2.865-.023-25.208zM714.512 448.005h-13.084l2.56 28.073 10.07-2.938.454-25.135z" />
                                <ellipse cx="863.998" cy="647.212" rx="10.903" ry="4.089" transform="rotate(-4.182 -1597.917 2760.883)" fill="#2f2e41" />
                                <circle cx="722.319" cy="403.229" r="14.719" fill="#fff" />
                                <circle cx="722.319" cy="403.229" r="4.906" fill="#3f3d56" />
                                <ellipse cx="929.598" cy="558.041" rx="21.534" ry="6.76" transform="rotate(-53.55 676.946 630.314)" fill="#2f2e41" />
                                <path d="M702.397 436.339c0-3.474 8.945-10.334 18.87-10.334s19.233 9.78 19.233 13.254-9.306-.674-19.232-.674-18.871 1.228-18.871-2.246z" fill="#fff" />
                                <path d="M844.177 378.542L608.43 345.86a7.008 7.008 0 01-5.972-7.895l26.502-191.172a7.008 7.008 0 017.894-5.973l235.746 32.682a7.008 7.008 0 015.972 7.894L852.072 372.57a7.008 7.008 0 01-7.895 5.973z" fill="#007bff" />
                                <path d="M759.722 318.88l-120.354-16.685a2.477 2.477 0 01-.445-.098l70.59-90.706a4.024 4.024 0 016.938.961l29.03 71.463 1.39 3.42z" fill="#fff" />
                                <path opacity=".2" style={{ isolation: "isolate" }} d="M759.722 318.879l-41.092-5.696 24.234-26.504 1.745-1.909.872-.955 1.391 3.42 12.85 31.644z" />
                                <path d="M826.735 328.17l-104.097-14.431 24.234-26.504 1.744-1.91 31.58-34.54a5.37 5.37 0 017.96.623 4.796 4.796 0 01.313.524z" fill="#fff" />
                                <circle cx="761.186" cy="224.184" r="18" fill="#fff" />
                                <path fill="#3f3d56" d="M640.876 155.517l21.792 3.022-3.021 21.791-21.792-3.02z" />
                                <path d="M765.187 374.08c3.477-15.573-7.64-31.31-24.83-35.148s-33.943 5.675-37.42 21.249 7.914 21.317 25.104 25.156 33.668 4.317 37.146-11.256z" fill="#f2f2f2" />
                                <ellipse cx="892.69" cy="647.142" rx="10.903" ry="4.089" transform="rotate(-4.182 -1569.224 2760.814)" fill="#2f2e41" />
                            </svg>
                        </div>
                        <div className="d-none ms-auto">
                            <div className="row row_step">
                                <div className="col-sm-6 pb-3 pt-3">
                                    <div className="pb-3 pt-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40" fill="currentColor" className="mb-3 text-white">
                                            <path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm13.464 12.536L20 12l-3.536-3.536L15.05 9.88 17.172 12l-2.122 2.121 1.414 1.415zM6.828 12L8.95 9.879 7.536 8.464 4 12l3.536 3.536L8.95 14.12 6.828 12zm4.416 5l3.64-10h-2.128l-3.64 10h2.128z"></path>
                                        </svg>
                                        <h4 className="fw-bold h5 text-info">1. {t('Home.step.1.title')}</h4>
                                        <p className="text-white">{t('Home.step.1.desc')}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 pb-3 pt-3">
                                    <div className="pb-3 pt-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40" fill="currentColor" className="mb-3 text-primary">
                                            <path d="M5.636 12.707l1.828 1.829L8.88 13.12 7.05 11.293l1.414-1.414 1.829 1.828 1.414-1.414L9.88 8.464l1.414-1.414L13.12 8.88l1.415-1.415-1.829-1.828 2.829-2.828a1 1 0 0 1 1.414 0l4.242 4.242a1 1 0 0 1 0 1.414L8.464 21.192a1 1 0 0 1-1.414 0L2.808 16.95a1 1 0 0 1 0-1.414l2.828-2.829zm8.485 5.656l4.243-4.242L21 16.757V21h-4.242l-2.637-2.637zM5.636 9.878L2.807 7.05a1 1 0 0 1 0-1.415l2.829-2.828a1 1 0 0 1 1.414 0L9.88 5.635 5.636 9.878z"></path>
                                        </svg>
                                        <h4 className="fw-bold h5 text-info">2. {t('Home.step.2.title')}</h4>
                                        <p className="text-white">{t('Home.step.2.desc')}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 pb-3 pt-3">
                                    <div className="pb-3 pt-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40" fill="currentColor" className="mb-3 text-primary">
                                            <path d="M15 21h-2v-3h-2v3H9v-2H7v2H4a1 1 0 0 1-1-1v-3h2v-2H3v-2h3v-2H3V9h2V7H3V4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v9h9a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-3v-2h-2v2z"></path>
                                        </svg>
                                        <h4 className="fw-bold h5 text-info">3. {t('Home.step.3.title')}</h4>
                                        <p className="text-white">{t('Home.step.3.desc')}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 pb-3 pt-3">
                                    <div className="pb-3 pt-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40" fill="currentColor" className="mb-3 text-primary">
                                            <path d="M8 5h3v9H8v3H6v-3H3V5h3V2h2v3zm10 5h3v9h-3v3h-2v-3h-3v-9h3V7h2v3z"></path>
                                        </svg>
                                        <h4 className="fw-bold h5 text-info">4. {t('Home.step.4.title')}</h4>
                                        <p className="text-white">{t('Home.step.4.desc')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-light pb-5 pt-5 text-center text-lg-start">
                <div className="col-lg-6 me-auto ms-auto text-center">
                    <h2 className="h6 mb-1 text-secondary">Leden Go</h2>
                    <h3 className="fw-bold h2 mb-3 text-primary">{t('Home.faq.title')}</h3>
                    <div style={{ width: "25%", height: "5px" }} className="bg-secondary me-auto ms-auto" data-pgc-define="divider"></div>
                </div>
                <div className="container pb-4 pt-4">
                    <div className="align-items-center row">
                        <div className="col-lg-6 mb-auto mt-auto p-5 text-center">
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="878.64" height="552.215" viewBox="0 0 878.64 552.215" className="img-fluid">
                                <path d="M837.863 552.215H95.229a24.9 24.9 0 01-24.9-24.901q403.806-46.93 792.435 0a24.9 24.9 0 01-24.901 24.9z" fill="#ccc" />
                                <path d="M862.764 528.779l-792.436-1.465 91.841-154.532.44-.733V31.478A31.475 31.475 0 01194.085 0h540.526a31.475 31.475 0 0131.478 31.478v342.915z" fill="#e6e6e6" />
                                <path d="M193.368 21.971a10.265 10.265 0 00-10.253 10.254v310.529a10.265 10.265 0 0010.253 10.253h546.356a10.266 10.266 0 0010.253-10.253V32.224a10.265 10.265 0 00-10.253-10.253z" fill="#fff" />
                                <path d="M196.959 394.02a4.406 4.406 0 00-3.992 2.558l-28.3 61.52a4.394 4.394 0 003.992 6.231h595.112a4.394 4.394 0 003.929-6.36l-30.76-61.52a4.372 4.372 0 00-3.93-2.428z" fill="#ccc" />
                                <circle cx="464.349" cy="10.253" r="6.591" fill="#fff" />
                                <path d="M405.854 476.047a4.401 4.401 0 00-4.242 3.252l-7.099 26.365a4.394 4.394 0 004.243 5.537h134.997a4.393 4.393 0 004.152-5.831l-9.127-26.366a4.397 4.397 0 00-4.152-2.957zM766.09 366.922v5.86H162.169l.454-.733v-5.127H766.09zM691.547 380.344a1.072 1.072 0 00-1.033.792l-1.728 6.42a1.07 1.07 0 001.033 1.349h32.874a1.07 1.07 0 001.01-1.42l-2.222-6.421a1.07 1.07 0 00-1.011-.72zM643.547 380.344a1.072 1.072 0 00-1.033.792l-1.728 6.42a1.07 1.07 0 001.033 1.349h32.874a1.07 1.07 0 001.01-1.42l-2.222-6.421a1.07 1.07 0 00-1.011-.72z" fill="#ccc" />
                                <path d="M504.481 421.14h2.577L501.905 76.75h-2.577l.103 6.87-57.747.001.103-6.87h-2.576l-5.153 344.388h2.576l.334-22.33h67.18zm-3.713-248.2h-60.42l.63-42.083h59.16zm.038 2.576l.63 42.082H439.68l.63-42.082zm.668 44.659l.63 42.082h-63.092l.63-42.082zm.669 44.659l.63 42.082h-64.43l.63-42.082zm.668 44.659l.63 42.082h-65.766l.63-42.082zM499.47 86.198l.63 42.083h-59.084l.63-42.083zm-62.463 310.036l.63-42.083h65.842l.63 42.083z" fill="#3f3d56" />
                                <circle cx="457.794" cy="153.358" r="42.012" fill="#2f2e41" />
                                <path fill="#2f2e41" d="M469.947 184.665l12.56-2.274 4.073 22.502-12.56 2.273zM444.83 189.212l12.558-2.274 4.074 22.502-12.56 2.273z" />
                                <ellipse cx="613.672" cy="387.298" rx="10.636" ry="3.989" transform="rotate(-40.261 296.132 519.53)" fill="#2f2e41" />
                                <ellipse cx="638.267" cy="382.846" rx="10.636" ry="3.989" transform="rotate(-40.261 320.726 515.077)" fill="#2f2e41" />
                                <circle cx="454.853" cy="143.081" r="14.359" fill="#fff" />
                                <circle cx="448.952" cy="137.934" r="4.786" fill="#3f3d56" />
                                <path d="M490.936 107.653c.632-15.554-12.773-28.728-29.94-29.425s-31.597 11.346-32.23 26.9 11.303 19.087 28.47 19.784 33.068-1.706 33.7-17.26z" fill="#007bff" />
                                <ellipse cx="579.682" cy="319.596" rx="6.594" ry="21.006" transform="rotate(-77.09 390.211 333.489)" fill="#2f2e41" />
                                <ellipse cx="662.15" cy="333.311" rx="6.594" ry="21.006" transform="rotate(-77.09 472.68 347.203)" fill="#2f2e41" />
                                <path d="M469.333 169.914a9.572 9.572 0 11-18.835 3.429l-.004-.018c-.941-5.203 3.08-7.043 8.283-7.985s9.614-.628 10.556 4.574z" fill="#fff" />
                                <ellipse cx="469.866" cy="467.526" rx="21.534" ry="6.76" transform="rotate(-69.082 263.212 497.296)" fill="#2f2e41" />
                                <circle cx="430.059" cy="500.048" r="43.067" transform="rotate(-80.783 247.526 507.53)" fill="#2f2e41" />
                                <path fill="#2f2e41" d="M249.753 359.955h13.084v23.442h-13.084zM275.921 359.955h13.084v23.442h-13.084z" />
                                <ellipse cx="260.656" cy="383.67" rx="10.903" ry="4.089" fill="#2f2e41" />
                                <ellipse cx="286.824" cy="383.125" rx="10.903" ry="4.089" fill="#2f2e41" />
                                <path d="M227.602 286.104c-3.477-15.573 7.639-31.31 24.829-35.149s33.944 5.676 37.421 21.25-7.915 21.317-25.105 25.155-33.667 4.318-37.145-11.256z" fill="#007bff" />
                                <ellipse cx="381.55" cy="480.065" rx="6.76" ry="21.534" transform="rotate(-64.626 163.743 520.14)" fill="#2f2e41" />
                                <circle cx="82.901" cy="369.763" r="43.067" fill="#2f2e41" />
                                <path fill="#2f2e41" d="M63.275 403.563h13.084v23.442H63.275zM89.443 403.563h13.084v23.442H89.443z" />
                                <ellipse cx="74.178" cy="427.277" rx="10.903" ry="4.089" fill="#2f2e41" />
                                <ellipse cx="100.346" cy="426.732" rx="10.903" ry="4.089" fill="#2f2e41" />
                                <circle cx="83.991" cy="358.86" r="14.719" fill="#fff" />
                                <circle cx="83.991" cy="358.86" r="4.906" fill="#3f3d56" />
                                <path d="M41.124 329.712c-3.477-15.574 7.639-31.31 24.829-35.149s33.944 5.675 37.421 21.249-7.915 21.318-25.105 25.156-33.667 4.318-37.145-11.256z" fill="#e6e6e6" />
                                <ellipse cx="268.669" cy="387.383" rx="21.534" ry="6.76" transform="rotate(-69.082 62.015 417.153)" fill="#2f2e41" />
                                <circle cx="228.861" cy="419.905" r="43.067" transform="rotate(-80.783 46.328 427.387)" fill="#2f2e41" />
                                <path fill="#2f2e41" d="M48.556 279.812H61.64v23.442H48.556zM74.723 279.812h13.084v23.442H74.723z" />
                                <ellipse cx="59.459" cy="303.527" rx="10.903" ry="4.089" fill="#2f2e41" />
                                <ellipse cx="85.627" cy="302.982" rx="10.903" ry="4.089" fill="#2f2e41" />
                                <circle cx="69.272" cy="235.11" r="14.719" fill="#fff" />
                                <circle cx="69.272" cy="235.11" r="4.906" fill="#3f3d56" />
                                <path d="M26.405 205.961c-3.478-15.573 7.639-31.31 24.828-35.149s33.945 5.676 37.422 21.25-7.915 21.317-25.105 25.155-33.668 4.318-37.145-11.256z" fill="#007bff" />
                                <ellipse cx="180.353" cy="399.922" rx="6.76" ry="21.534" transform="rotate(-64.626 -37.454 439.998)" fill="#2f2e41" />
                                <path d="M47.027 261.55c0 4.215 10.853 12.538 22.897 12.538s23.335-11.867 23.335-16.082-11.292.818-23.335.818-22.897-1.49-22.897 2.726z" fill="#fff" />
                                <path d="M110.524 81.569a3.003 3.003 0 00-3 3v124a3.003 3.003 0 003 3h287a3.003 3.003 0 003-3v-124a3.003 3.003 0 00-3-3z" fill="#007bff" />
                                <circle cx="188.018" cy="146.569" r="29.121" fill="#fff" />
                                <path data-name="Path 40" d="M253.744 142.694a3.405 3.405 0 000 6.81h91.945a3.405 3.405 0 10.112-6.81h-.112zM253.744 122.967a3.405 3.405 0 000 6.81h45.915a3.405 3.405 0 10.112-6.81h-.112zM253.744 163.36a3.405 3.405 0 000 6.81h91.945a3.405 3.405 0 10.112-6.81h-.112z" fill="#fff" />
                                <ellipse cx="853.282" cy="546.503" rx="6.76" ry="21.534" transform="rotate(-20.918 301.938 894.773)" fill="#2f2e41" />
                                <circle cx="884.09" cy="588.025" r="43.067" transform="rotate(-9.217 -274.834 1497.71)" fill="#2f2e41" />
                                <path fill="#2f2e41" d="M743.035 447.932h-13.083l2.56 28.073 10.546-2.865-.023-25.208zM714.512 448.005h-13.084l2.56 28.073 10.07-2.938.454-25.135z" />
                                <ellipse cx="863.998" cy="647.212" rx="10.903" ry="4.089" transform="rotate(-4.182 -1597.917 2760.883)" fill="#2f2e41" />
                                <circle cx="722.319" cy="403.229" r="14.719" fill="#fff" />
                                <circle cx="722.319" cy="403.229" r="4.906" fill="#3f3d56" />
                                <ellipse cx="929.598" cy="558.041" rx="21.534" ry="6.76" transform="rotate(-53.55 676.946 630.314)" fill="#2f2e41" />
                                <path d="M702.397 436.339c0-3.474 8.945-10.334 18.87-10.334s19.233 9.78 19.233 13.254-9.306-.674-19.232-.674-18.871 1.228-18.871-2.246z" fill="#fff" />
                                <path d="M844.177 378.542L608.43 345.86a7.008 7.008 0 01-5.972-7.895l26.502-191.172a7.008 7.008 0 017.894-5.973l235.746 32.682a7.008 7.008 0 015.972 7.894L852.072 372.57a7.008 7.008 0 01-7.895 5.973z" fill="#007bff" />
                                <path d="M759.722 318.88l-120.354-16.685a2.477 2.477 0 01-.445-.098l70.59-90.706a4.024 4.024 0 016.938.961l29.03 71.463 1.39 3.42z" fill="#fff" />
                                <path opacity=".2" style={{ isolation: "isolate" }} d="M759.722 318.879l-41.092-5.696 24.234-26.504 1.745-1.909.872-.955 1.391 3.42 12.85 31.644z" />
                                <path d="M826.735 328.17l-104.097-14.431 24.234-26.504 1.744-1.91 31.58-34.54a5.37 5.37 0 017.96.623 4.796 4.796 0 01.313.524z" fill="#fff" />
                                <circle cx="761.186" cy="224.184" r="18" fill="#fff" />
                                <path fill="#3f3d56" d="M640.876 155.517l21.792 3.022-3.021 21.791-21.792-3.02z" />
                                <path d="M765.187 374.08c3.477-15.573-7.64-31.31-24.83-35.148s-33.943 5.675-37.42 21.249 7.914 21.317 25.104 25.156 33.668 4.317 37.146-11.256z" fill="#f2f2f2" />
                                <ellipse cx="892.69" cy="647.142" rx="10.903" ry="4.089" transform="rotate(-4.182 -1569.224 2760.814)" fill="#2f2e41" />
                            </svg>
                        </div>
                        <div className="col-lg-6 pb-3 pt-3">
                            <div className="container-fluid">
                                <div className="accordion" id="panels1">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="collapse0"> <button className="accordion-button bg-secondary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne0" aria-expanded="true" aria-controls="collapseOne0">Q: {t('Home.faq.1.q')}</button> </h2>
                                        <div id="collapseOne0" className="accordion-collapse collapse show" aria-labelledby="collapse0" data-bs-parent="#panels1">
                                            <div className="accordion-body">{t('Home.faq.1.a')}</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="collapse1"> <button className="accordion-button bg-secondary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-controls="collapseOne1">Q: {t('Home.faq.2.q')}</button> </h2>
                                        <div id="collapseOne1" className="accordion-collapse collapse " aria-labelledby="collapse1" data-bs-parent="#panels1">
                                            <div className="accordion-body">R: {t('Home.faq.2.a')}</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="collapse1"> <button className="accordion-button bg-secondary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2" aria-expanded="false" aria-controls="collapseOne2">Q : {t('Home.faq.3.q')}</button> </h2>
                                        <div id="collapseOne2" className="accordion-collapse collapse " aria-labelledby="collapse1" data-bs-parent="#panels1">
                                            <div className="accordion-body">Réponse: {t('Home.faq.3.a')}</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="collapse2"> <button className="accordion-button bg-secondary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo3" aria-expanded="false" aria-controls="collapseTwo3">Q : {t('Home.faq.4.q')}</button> </h2>
                                        <div id="collapseTwo3" className="accordion-collapse collapse" aria-labelledby="collapse2" data-bs-parent="#panels1">
                                            <div className="accordion-body">R: {t('Home.faq.4.a')}</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="collapse3"> <button className="accordion-button bg-secondary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree0" aria-expanded="false" aria-controls="collapseThree0">Q: {t('Home.faq.5.q')}</button> </h2>
                                        <div id="collapseThree0" className="accordion-collapse collapse" aria-labelledby="collapse3" data-bs-parent="#panels1">
                                            <div className="accordion-body">R: {t('Home.faq.5.a')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-light pb-5 pt-5 text-center" data-pgc-define="partner" data-pgc-define-photo-preview-only>
                <div className="col-lg-6 ms-auto me-auto">
                    <h2 className="h6 mb-1 text-secondary">Leden Go</h2>
                    <h3 className="fw-bold h2 mb-3 text-primary">{t('Home.partnerTitle')}</h3>
                    <div style={{ width: "25%", height: "5px" }} className="bg-secondary me-auto ms-auto" data-pgc-define="divider"></div>
                </div>
                <div className="container">
                    <div className="justify-content-center row">
                        <div className="col-lg-3 col-sm-6 pb-3 pt-3">
                            <img src="assets/img/partner1.png" width="75" />
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <svg viewBox="0 0 1440 185" preserveAspectRatio="none" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" className="d-block  text-primary" width="100%" height="160">
                    <path d="M0 185 H 1440 V 60 C 1114 355 700 35 516 35 C 333 35 246 199 0 60 V 185 Z" />
                </svg>
            </div>
            <section className="bg-primary pb-5 pt-5 text-center text-lg-end text-white-50">
                <div className="container">
                    <div className="align-items-center  row">
                        <div className="col-lg-6 pb-3 pt-3">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="180 110 1250 960" className="col-10 col-lg-12 col-sm-9 img-fluid">
                                <path d="M235.522 1065.61L190.202 510a59.595 59.595 0 0111.09-39.75l226.17-313a59.593 59.593 0 0153.54-24.49L1255.26 201a59.609 59.609 0 0133.27 13.877 59.585 59.585 0 0119.18 30.513l106.38 409.42a59.435 59.435 0 01-.2 30.68L1309.8 1067l-1074.278-1.39z" fill="#F4F4F4" opacity="0.1" />
                                <path d="M901.798 532s15 2.06 84 .63c18.662 11.33 99.562 4.13 131.452 1.12 36.85 6.84 55.08-11 38.71-20.65-5-3-13.38-5.19-25.5-5.64.79-38.37-94.11-51-120.55 5-14.002-11.31-43.192-10.29-45.112 5-27-4.95-48.33.64-58.09 6.5-5.12 3.16-7.05 6.32-4.91 8.04z" fill="#fff" />
                                <path opacity=".08" d="M901.802 532s15 2.06 84 .63c18.658 11.33 99.558 4.13 131.448 1.12 36.85 6.84 55.08-11 38.71-20.65.13 2.4-.69 4.756-2.28 6.56-3.73 4.28-10.78 6.05-17.21 5.51-6.43-.54-12.37-3.08-17.75-6-2.57-1.39-5.21-2.92-8.28-3.22-5.46-.53-10.18 2.92-14 6.11-3.17-5.12-11.19-7.89-17.94-6.2-6.75 1.69-11.43 7.66-10.45 13.33-13.2-2.36-26.67 3.95-40.11 2.94-15.36-1.15-29.198-11.68-44.238-9-3.82.67-7.34 2.17-11.16 2.81-3.82.64-7.61.37-11.44.3-12.29-.23-24.59 1.35-36.86.73a71.095 71.095 0 01-17.51-3c-5.14 3.15-7.07 6.31-4.93 8.03z" fill="#21252B" />
                                <path d="M628.21 693.75H500.5v371.64h127.71V693.75z" fill="#DADDDF" />
                                <path d="M615.828 714.73h-44.68v22.57h44.68v-22.57z" fill="#868A91" />
                                <path opacity=".2" d="M612.858 714.73h-41.71v22.57h41.71v-22.57z" fill="#fff" />
                                <path d="M564.149 714.73h-44.68v22.57h44.68v-22.57z" fill="#868A91" />
                                <path opacity=".2" d="M561.179 714.73h-41.71v22.57h41.71v-22.57z" fill="#fff" />
                                <path d="M615.828 757.211h-44.68v22.57h44.68v-22.57z" fill="#868A91" />
                                <path opacity=".2" d="M612.858 757.211h-41.71v22.57h41.71v-22.57z" fill="#fff" />
                                <path d="M564.149 757.211h-44.68v22.57h44.68v-22.57z" fill="#868A91" />
                                <path opacity=".2" d="M561.179 757.211h-41.71v22.57h41.71v-22.57z" fill="#fff" />
                                <path d="M615.828 811.5h-44.68v22.57h44.68V811.5z" fill="#868A91" />
                                <path opacity=".2" d="M612.858 811.5h-41.71v22.57h41.71V811.5z" fill="#fff" />
                                <path d="M564.149 811.5h-44.68v22.57h44.68V811.5z" fill="#868A91" />
                                <path opacity=".2" d="M561.179 811.5h-41.71v22.57h41.71V811.5z" fill="#fff" />
                                <path d="M615.828 842.699h-44.68v22.57h44.68v-22.57z" fill="#868A91" />
                                <path opacity=".2" d="M612.858 842.699h-41.71v22.57h41.71v-22.57z" fill="#fff" />
                                <path d="M564.149 842.699h-44.68v22.57h44.68v-22.57z" fill="#868A91" />
                                <path opacity=".2" d="M561.179 842.699h-41.71v22.57h41.71v-22.57z" fill="#fff" />
                                <path d="M615.828 893.939h-44.68v22.57h44.68v-22.57z" fill="#868A91" />
                                <path opacity=".2" d="M612.858 893.939h-41.71v22.57h41.71v-22.57z" fill="#fff" />
                                <path d="M564.149 893.939h-44.68v22.57h44.68v-22.57z" fill="#868A91" />
                                <path opacity=".2" d="M561.179 893.939h-41.71v22.57h41.71v-22.57z" fill="#fff" />
                                <path d="M615.828 925.141h-44.68v22.57h44.68v-22.57z" fill="#868A91" />
                                <path opacity=".2" d="M612.858 925.141h-41.71v22.57h41.71v-22.57z" fill="#fff" />
                                <path d="M564.149 925.141h-44.68v22.57h44.68v-22.57z" fill="#868A91" />
                                <path opacity=".2" d="M561.179 925.141h-41.71v22.57h41.71v-22.57z" fill="#fff" />
                                <path d="M615.828 980.91h-44.68v22.57h44.68v-22.57z" fill="#868A91" />
                                <path opacity=".2" d="M612.858 980.91h-41.71v22.57h41.71v-22.57z" fill="#fff" />
                                <path d="M564.149 980.91h-44.68v22.57h44.68v-22.57z" fill="#868A91" />
                                <path opacity=".2" d="M561.179 980.91h-41.71v22.57h41.71v-22.57z" fill="#fff" />
                                <path d="M615.828 1022.11h-44.68v22.57h44.68v-22.57z" fill="#868A91" />
                                <path opacity=".2" d="M612.858 1022.11h-41.71v22.57h41.71v-22.57z" fill="#fff" />
                                <path d="M564.149 1022.11h-44.68v22.57h44.68v-22.57z" fill="#868A91" />
                                <path opacity=".2" d="M561.179 1022.11h-41.71v22.57h41.71v-22.57z" fill="#fff" />
                                <path d="M636.122 681.18h-184.02v13.58h184.02v-13.58z" fill="#868A91" />
                                <path d="M500.498 694.631h-40.17v370.759h40.17V694.631z" fill="#B2B6BB" />
                                <path opacity=".2" d="M628.208 793.07h-167.88v3.88h167.88v-3.88zM628.208 877.029h-167.88v3.88h167.88v-3.88zM628.208 961.74h-167.88v3.88h167.88v-3.88zM500.599 681.18h-48.81v13.25h48.81v-13.25z" fill="#000" />
                                <path d="M319.9 654.262v411.128l-.14-.25H260.5V654.262h59.4z" fill="#B2B6BB" />
                                <path opacity=".5" d="M466.398 676.602h-5.14v21.25h5.14v-21.25zM466.398 712.352h-5.14v21.25h5.14v-21.25zM466.398 749.521h-5.14v21.25h5.14v-21.25zM466.398 786.691h-5.14v21.25h5.14v-21.25zM466.398 823.861h-5.14v21.25h5.14v-21.25zM466.398 861.031h-5.14v21.25h5.14v-21.25zM466.398 901.992h-5.14v21.25h5.14v-21.25zM466.398 942.961h-5.14v21.25h5.14v-21.25zM466.398 983.922h-5.14v21.248h5.14v-21.248zM466.398 1024.88h-5.14v21.25h5.14v-21.25z" fill="#fff" />
                                <path d="M480.398 648.281h-160.5v417.109h160.5V648.281z" className="change-color-in-svg" fill="#9d5625" />
                                <path d="M305.22 676.59H260.5v21.26h44.72v-21.26zM305.22 711.561H260.5v21.25h44.72v-21.25zM305.22 746.531H260.5v21.25h44.72v-21.25zM305.22 786.691H260.5v21.25h44.72v-21.25zM305.22 823.861H260.5v21.25h44.72v-21.25zM305.22 861.031H260.5v21.25h44.72v-21.25zM305.22 901.99H260.5v21.25h44.72v-21.25zM305.22 942.951H260.5v21.25h44.72v-21.25zM271.14 1005.17h34.08v-21.26h-39.88" fill="#B2B6BB" />
                                <path opacity=".3" d="M305.216 676.59h-44.72v21.26h44.72v-21.26zM305.216 711.561h-44.72v21.25h44.72v-21.25zM305.216 746.529h-44.72v21.25h44.72v-21.25zM305.216 786.689h-44.72v21.25h44.72v-21.25zM305.216 823.859h-44.72v21.25h44.72v-21.25zM305.216 861.029h-44.72v21.25h44.72v-21.25zM305.216 901.99h-44.72v21.25h44.72v-21.25zM305.216 942.949h-44.72v21.25h44.72v-21.25zM305.217 1005.17h-44.54l-.22-21.26h44.76v21.26zM260.477 1024.88h44.74v21.25h-44.52l-.22-21.25z" fill="#fff" />
                                <path d="M480.398 648.281h-160.5v417.109h160.5V648.281z" fill="#DADDDF" />
                                <path d="M466.396 676.602h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M461.256 676.602h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M466.396 712.352h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M461.256 712.352h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M466.396 749.521h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M461.256 749.521h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M466.396 786.691h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M461.256 786.691h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M466.396 823.861h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M461.256 823.861h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M466.396 861.031h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M461.256 861.031h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M466.396 901.99h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M461.256 901.99h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M466.396 942.961h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M461.256 942.961h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M466.396 983.92h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M461.256 983.92h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M466.396 1024.88h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M461.256 1024.88h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M319.898 635.891h-55.98v8.18h55.98v-8.18z" className="change-color-in-svg" fill="#9d5625" />
                                <path d="M480.398 633.9h-216.48v10.18h216.48V633.9z" fill="#DADDDF" />
                                <path d="M484.938 644.08h-228.61v10.18h228.61v-10.18zM484.938 625.721h-228.61v10.18h228.61v-10.18z" fill="#B2B6BB" />
                                <path opacity=".05" d="M484.938 644.08h-228.61v10.18h228.61v-10.18zM484.938 625.721h-228.61v10.18h228.61v-10.18z" fill="#B2B6BB" />
                                <path opacity=".2" d="M319.898 644.08h-63.57v10.18h63.57v-10.18zM319.898 625.721h-63.57v10.18h63.57v-10.18z" fill="#000" />
                                <path opacity=".2" d="M319.898 635.891h-55.98v8.18h55.98v-8.18z" fill="#000" />
                                <path d="M1128.96 654.262v411.128l-.14-.25h-59.27V654.262h59.41z" fill="#B2B6BB" />
                                <path opacity=".5" d="M1275.45 676.602h-5.14v21.25h5.14v-21.25zM1275.45 712.352h-5.14v21.25h5.14v-21.25zM1275.45 749.521h-5.14v21.25h5.14v-21.25zM1275.45 786.691h-5.14v21.25h5.14v-21.25zM1275.45 823.861h-5.14v21.25h5.14v-21.25zM1275.45 861.031h-5.14v21.25h5.14v-21.25zM1275.45 901.992h-5.14v21.25h5.14v-21.25zM1275.45 942.961h-5.14v21.25h5.14v-21.25zM1275.45 983.922h-5.14v21.248h5.14v-21.248zM1275.45 1024.88h-5.14v21.25h5.14v-21.25z" fill="#fff" />
                                <path d="M1289.46 648.281h-160.5v417.109h160.5V648.281z" className="change-color-in-svg" fill="#9d5625" />
                                <path d="M1114.27 676.59h-44.72v21.26h44.72v-21.26zM1114.27 711.561h-44.72v21.25h44.72v-21.25zM1114.27 746.531h-44.72v21.25h44.72v-21.25zM1114.27 786.691h-44.72v21.25h44.72v-21.25zM1114.27 823.861h-44.72v21.25h44.72v-21.25zM1114.27 861.031h-44.72v21.25h44.72v-21.25zM1114.27 901.99h-44.72v21.25h44.72v-21.25zM1114.27 942.951h-44.72v21.25h44.72v-21.25zM1080.2 1005.17h34.07v-21.26h-39.87" fill="#B2B6BB" />
                                <path opacity=".3" d="M1114.27 676.59h-44.72v21.26h44.72v-21.26zM1114.27 711.561h-44.72v21.25h44.72v-21.25zM1114.27 746.529h-44.72v21.25h44.72v-21.25zM1114.27 786.689h-44.72v21.25h44.72v-21.25zM1114.27 823.859h-44.72v21.25h44.72v-21.25zM1114.27 861.029h-44.72v21.25h44.72v-21.25zM1114.27 901.99h-44.72v21.25h44.72v-21.25zM1114.27 942.949h-44.72v21.25h44.72v-21.25zM1114.27 1005.17h-44.53l-.22-21.26h44.75v21.26zM1069.54 1024.88h44.73v21.25h-44.51l-.22-21.25z" fill="#fff" />
                                <path d="M1289.46 648.281h-160.5v417.109h160.5V648.281z" fill="#DADDDF" />
                                <path d="M1275.45 676.602h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M1270.31 676.602h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M1275.45 712.352h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M1270.31 712.352h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M1275.45 749.521h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M1270.31 749.521h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M1275.45 786.691h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M1270.31 786.691h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M1275.45 823.861h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M1270.31 823.861h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M1275.45 861.031h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M1270.31 861.031h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M1275.45 901.99h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M1270.31 901.99h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M1275.45 942.961h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M1270.31 942.961h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M1275.45 983.92h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M1270.31 983.92h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M1275.45 1024.88h-132.49v21.25h132.49v-21.25z" fill="#868A91" />
                                <path opacity=".2" d="M1270.31 1024.88h-127.35v21.25h127.35v-21.25z" fill="#fff" />
                                <path d="M1128.96 635.891h-55.98v8.18h55.98v-8.18z" className="change-color-in-svg" fill="#9d5625" />
                                <path d="M1289.46 633.9h-216.48v10.18h216.48V633.9z" fill="#DADDDF" />
                                <path d="M1294 644.08h-228.61v10.18H1294v-10.18zM1294 625.721h-228.61v10.18H1294v-10.18z" fill="#B2B6BB" />
                                <path opacity=".05" d="M1294 644.08h-228.61v10.18H1294v-10.18zM1294 625.721h-228.61v10.18H1294v-10.18z" fill="#B2B6BB" />
                                <path opacity=".2" d="M1128.96 644.08h-63.57v10.18h63.57v-10.18zM1128.96 625.721h-63.57v10.18h63.57v-10.18z" fill="#000" />
                                <path opacity=".2" d="M1128.96 635.891h-55.98v8.18h55.98v-8.18z" fill="#000" />
                                <g opacity=".1" fill="#fff">
                                    <path opacity=".1" d="M500.598 681.181h-15.66v-55.46H256.328V1065.39h371.88V694.751h7.91v-13.57h-135.52zM1128.96 625.721h-63.57V1065.39H1294V625.721h-165.04z" />
                                </g>
                                <path d="M936.28 713.871l-5.82-4.34a6.004 6.004 0 00-5.1-1l-14.25 3.69-77.53-28.76-35.2-40-21.38 22.74 35.52 35.73a18.212 18.212 0 008.81 5.22l85.9 20.34c10 2.66 18.26-5.5 18.26-5.5l7.9 5.71-.93 2.57a4.26 4.26 0 01-1.94 2.28l-8.22 4.57a1.877 1.877 0 00-.86 1 1.83 1.83 0 00.16 1.493 1.827 1.827 0 001.22.877l.45.09c.335.063.681.032 1-.09l10.73-4.12a5.992 5.992 0 003.45-3.48l3.82-10-5.99-9.02z" fill="#FC9D9D" />
                                <path opacity=".15" d="M907 724.63l-88.4-25.25-36.21-39.61-6.39 5.589 36.51 36.571a18.22 18.22 0 008.81 5.22l85.9 20.34c8.21 2.19 15.26-2.941 17.51-4.831-11.11 5.28-17.73 1.971-17.73 1.971z" fill="#000" />
                                <path d="M771.762 608.609l31.17 35.28-28.22 24.95-27.11-34.95 24.16-25.28z" className="change-color-in-svg" fill="#9d5625" />
                                <path d="M772.93 666.331l28.07-24.62 2 2.29-28 25-2.07-2.67z" fill="#F4F4F4" />
                                <path opacity=".1" d="M936.282 713.869l-5.82-4.34a6.006 6.006 0 00-5.1-1l-14.25 3.69-77.53-28.76-33-37.48 2.37-2.09-31.17-35.28-24.18 25.29 27.11 34.94 2.55-2.25 35.28 35.34a18.2 18.2 0 008.81 5.22l85.9 20.34c.46.13.92.22 1.37.3h.26c.44.07.87.12 1.3.15h1.57c.42 0 .83 0 1.24-.08h.27c.39 0 .78-.11 1.16-.18h.22c.36-.07.7-.15 1-.24h.17c.37-.1.72-.21 1.07-.32l.27-.09c.34-.12.67-.24 1-.38l.18-.07.86-.38.18-.08.89-.45.21-.11c.28-.15.56-.3.82-.46h.1l.71-.45.14-.08.76-.57.12-.09c.45-.31.85-.61 1.18-.87.78-.62 1.21-1 1.21-1l.25.18 7.65 5.53-.93 2.57a4.26 4.26 0 01-1.94 2.28l-8.22 4.57a1.88 1.88 0 00-.86 1c-.08.247-.107.507-.08.764a1.85 1.85 0 00.756 1.298c.21.151.45.256.704.308l.45.09c.335.064.681.032 1-.09l10.71-4.17a5.997 5.997 0 003.45-3.48l3.82-10-5.99-9.02z" fill="#000" />
                                <path d="M868.921 940.549l-.05 22.11 28.83-.71 2.95-25.83-31.73 4.43z" fill="#FC9D9D" />
                                <path opacity=".15" d="M899.572 943.481l-15.76 1.37a6.826 6.826 0 00-4.56 6.43v11.12l-10.33.29v-21.4l31-.74-.35 2.93z" fill="#000" />
                                <path d="M867.059 959.741l.2 22.33a6.836 6.836 0 00.47 2.5 6.68 6.68 0 001.29 2.11 5.785 5.785 0 001.93 1.39 5.444 5.444 0 002.27.48l68.65-.62a2.354 2.354 0 001.74-.82 2.85 2.85 0 00.71-1.94 13.355 13.355 0 00-2.19-7.21 11.367 11.367 0 00-5.57-4.46l-15.56-5.5-19.74-16.7a4.996 4.996 0 00-6.87.25l-8.62 8.28a4.686 4.686 0 01-2.18 1.21 4.472 4.472 0 01-2.43-.1l-11.06-3.7a2.125 2.125 0 00-1.09-.07c-.368.069-.713.23-1 .47a2.396 2.396 0 00-.7.93 2.79 2.79 0 00-.25 1.17z" className="change-color-in-svg" fill="#9d5625" />
                                <path d="M867.059 959.741l.2 22.33a6.836 6.836 0 00.47 2.5 6.68 6.68 0 001.29 2.11 5.785 5.785 0 001.93 1.39 5.444 5.444 0 002.27.48l68.65-.62a2.354 2.354 0 001.74-.82 2.85 2.85 0 00.71-1.94 13.355 13.355 0 00-2.19-7.21 11.367 11.367 0 00-5.57-4.46l-15.56-5.5-19.74-16.7a4.996 4.996 0 00-6.87.25l-8.62 8.28a4.686 4.686 0 01-2.18 1.21 4.472 4.472 0 01-2.43-.1l-11.06-3.7a2.125 2.125 0 00-1.09-.07c-.368.069-.713.23-1 .47a2.396 2.396 0 00-.7.93 2.79 2.79 0 00-.25 1.17z" fill="#3F4249" />
                                <path d="M873.261 988.54l68.61-.62a2.378 2.378 0 001.74-.82 2.852 2.852 0 00.71-1.94c.013-.922-.102-1.84-.34-2.73l-76.59.68a6.876 6.876 0 002 3.91 5.624 5.624 0 003.83 1.52h.04z" fill="#21252B" />
                                <path opacity=".3" d="M873.261 988.54l68.61-.62a2.378 2.378 0 001.74-.82 2.852 2.852 0 00.71-1.94c.013-.922-.102-1.84-.34-2.73l-76.59.68a6.876 6.876 0 002 3.91 5.624 5.624 0 003.83 1.52h.04z" fill="#000" />
                                <path d="M900.899 960.219a.56.56 0 01-.4-.18.621.621 0 01-.14-.22.746.746 0 01-.05-.26.807.807 0 01.05-.27.803.803 0 01.13-.22l4.12-5a.576.576 0 01.44-.21.645.645 0 01.44.2.746.746 0 01.01.98l-4.11 5a.997.997 0 01-.49.19v-.01zM904.219 963a.562.562 0 01-.4-.17.621.621 0 01-.14-.22.81.81 0 010-.26.877.877 0 010-.28.692.692 0 01.13-.22l4.12-5a.564.564 0 01.43-.2.634.634 0 01.44.19c.119.135.183.31.18.49a.722.722 0 01-.17.49l-4.11 5a1.207 1.207 0 01-.48.18zM907.49 965.81a.596.596 0 01-.4-.17.773.773 0 01-.01-.98l4.12-5a.572.572 0 01.42-.189c.08 0 .158.017.231.049a.568.568 0 01.189.14.731.731 0 01.18.46.707.707 0 01-.14.47l-4.12 5a.686.686 0 01-.47.18v.04z" fill="#F4F4F4" />
                                <path opacity=".15" d="M944.319 985.159a9.744 9.744 0 00-.14-1.78 13.194 13.194 0 00-2-5.42 11.367 11.367 0 00-5.57-4.46l-15.61-5.5-8.88-7.49a.603.603 0 00.07-.32.73.73 0 00-.18-.46.559.559 0 00-.84 0l-2.31-2a.689.689 0 00.1-.37.723.723 0 00-.18-.49.63.63 0 00-.44-.19.58.58 0 00-.43.2l-2.33-2a.79.79 0 00.11-.36.744.744 0 00-.19-.5.64.64 0 00-.44-.2.577.577 0 00-.44.21l-3.33-2.81a5.298 5.298 0 00-2.22-1.11l1.61-14.07-31.73 4.43v16.66c-.317.08-.614.226-.87.43a2.413 2.413 0 00-.7.93 2.797 2.797 0 00-.25 1.17l.2 22.33a6.856 6.856 0 00.47 2.5 6.699 6.699 0 001.29 2.11 5.796 5.796 0 001.93 1.39 5.448 5.448 0 002.27.48l68.65-.62a2.334 2.334 0 001.74-.82 2.85 2.85 0 00.71-1.94l-.07.07z" fill="#000" />
                                <path d="M789.319 741.84l101.36 29.35s23.83 5.2 21.88 22.16l-10.81 148.57h-11.07l-15.49-144.16-97.38-45.29 11.51-10.63z" fill="#21252B" />
                                <path opacity=".3" d="M789.319 741.84l101.36 29.35s23.83 5.2 21.88 22.16l-10.81 148.57h-11.07l-15.49-144.16-97.38-45.29 11.51-10.63z" fill="#000" />
                                <path d="M903 942h-36v-10h37l-1 10z" fill="#21252B" />
                                <path opacity=".4" d="M903 942h-36v-10h37l-1 10z" fill="#000" />
                                <path opacity=".3" d="M878 932h-11v10h11v-10z" fill="#000" />
                                <path d="M528.298 900.309l-7.85-1.84a8.892 8.892 0 01-6.61-10.69l8.2-20.07a9.005 9.005 0 0111.23-5.15l4.84 1.21-9.81 36.54z" fill="#B2B6BB" />
                                <path d="M1087.69 1065.9c41.83-2.01 74.12-37.55 72.11-79.39-2.01-41.837-37.55-74.123-79.39-72.114-41.84 2.01-74.12 37.555-72.12 79.392 2.01 41.832 37.56 74.122 79.4 72.112z" fill="#21252B" />
                                <path d="M1030.8 989.399a53.172 53.172 0 008.55 29.701 53.212 53.212 0 0054.32 23.42 53.168 53.168 0 0027.46-14.17 53.254 53.254 0 0014.97-27.05 53.256 53.256 0 00-2.59-30.801 53.265 53.265 0 00-48.71-33.57 53.251 53.251 0 00-49.66 32.156 53.382 53.382 0 00-4.34 20.314z" fill="#FAFAFA" />
                                <path d="M1051.61 989.701a32.356 32.356 0 005.22 18.079c3.5 5.38 8.5 9.61 14.39 12.14 5.89 2.53 12.4 3.26 18.71 2.1a32.48 32.48 0 0016.72-8.65c4.6-4.47 7.76-10.2 9.1-16.476a32.42 32.42 0 00-1.58-18.757 32.442 32.442 0 00-42.13-18.144 32.331 32.331 0 00-10.62 6.888 32.39 32.39 0 00-9.81 22.82z" fill="#868A91" />
                                <path d="M1061.38 943.752l-3.25 1.702c-4.25 2.226-5.9 7.477-3.67 11.729l19.46 37.175c2.22 4.252 7.48 5.892 11.73 3.669l3.25-1.702c4.25-2.225 5.89-7.477 3.67-11.729l-19.46-37.175c-2.23-4.252-7.48-5.894-11.73-3.669z" fill="#3F4249" />
                                <path d="M1048 878.999s77.65-23.48 109.76 60.87l-162.44 50.29 52.68-111.16z" className="change-color-in-svg" fill="#9d5625" />
                                <path d="M619.764 1065.9c41.837-2.01 74.124-37.55 72.114-79.39-2.009-41.837-37.554-74.123-79.391-72.114-41.837 2.01-74.124 37.555-72.114 79.392 2.01 41.832 37.554 74.122 79.391 72.112z" fill="#21252B" />
                                <path d="M562.888 989.4a53.24 53.24 0 0062.87 53.12 53.17 53.17 0 0027.466-14.17 53.24 53.24 0 0012.374-57.851 53.244 53.244 0 00-86.58-18.521 53.222 53.222 0 00-16.13 37.422z" fill="#FAFAFA" />
                                <path d="M583.698 989.701a32.393 32.393 0 005.226 18.079 32.412 32.412 0 0014.389 12.14 32.507 32.507 0 0018.707 2.1 32.483 32.483 0 0016.72-8.65 32.422 32.422 0 007.517-35.233 32.425 32.425 0 00-29.669-20.436 32.404 32.404 0 00-23.08 9.18 32.408 32.408 0 00-9.81 22.82z" fill="#868A91" />
                                <path d="M679.371 969.11s8.48 1.54 13.32-14.53l51.43 8.15 1.88 18.49s-2.42 36.34-66.61 30.28l-.02-42.39z" fill="#3F4249" />
                                <path d="M517.669 926.16s-3.61-73.9 65.84-89.3v-9.48h243.32v19.07a10.184 10.184 0 01-2.05 6.15c-7.42 9.85-31.95 45.62-22 76.09 11.66 35.71 15.3 39.36 15.3 39.36h138.48s16.77-21.14 17.5-52.48c.73-31.34-12.3-84.93-22-119.19-8.84-31.14-14.43-57.19-14.43-57.19l41.55-13.85s76.871 100.51 80.001 106.17c3.13 5.66 13.81 18.84 11.3 47.72s-71.591 118.07-89.801 128.11c-18.21 10.04-34.54 10.68-50.87 13.19-14.17 2.18-119 .74-146.43.32a53.636 53.636 0 01-27.07-7.52c-8.2-5-14.37-13.2-21.18-26.71l-14.44-28.26-203.02-32.2z" className="change-color-in-svg" fill="#9d5625" />
                                <path d="M756.94 932.09c17-104.84-115.06-83.77-115.06-83.77-76.25 10-98.15 59-98.15 59l213.21 24.77z" fill="#DADDDF" />
                                <path d="M536.29 921l-30.649 48c-3.92 6.15 2.43 13.88 9.12 11a35.333 35.333 0 0010.24-7c14-13.34 39-48.46 39-48.46L536.29 921z" fill="#61656D" />
                                <path d="M694.392 848.32c13.6 2.6 27.11 6.77 38.27 14.22 11.16 7.45 18 16.61 23 29.06 4.84 12.11 3.63 30.28 1.21 40l14.9 3c5.79-19.3-.51-43-14.42-58.1-16.32-17.73-38.76-28.14-63-28.14" fill="#DADDDF" />
                                <path opacity=".1" d="M694.392 848.32c13.6 2.6 27.11 6.77 38.27 14.22 11.16 7.45 18 16.61 23 29.06 4.84 12.11 3.63 30.28 1.21 40l14.9 3c5.79-19.3-.51-43-14.42-58.1-16.32-17.73-38.76-28.14-63-28.14" fill="#000" />
                                <path d="M826.828 827.38a21.09 21.09 0 004.4-10.9c.38-6.11-2.41-13.75-9-13.75h-84a24.654 24.654 0 00-24.65 24.65h113.25z" fill="#3F4249" />
                                <path d="M937.609 739.19l-14.61-30.47a18.65 18.65 0 017.39-23.83l43.69-26.07a14.743 14.743 0 017.55-2.08h9.45l3.78 61.59-14.57 5.58 9.22 15-51.9.28z" className="change-color-in-svg" fill="#9d5625" />
                                <path d="M991.211 659s14.949 3.42 16.829 26.47c1.88 23.05-13.179 30.22-13.179 30.22l-3.65-56.69z" fill="#B2B6BB" />
                                <path d="M942.709 710.099s-30.22 14.33-33.91 16.68c-2.56 1.63-4.76 4.48-2.75 9.5 3.42 8.56 17.55.51 17.55.51l28.13-14.06s10.81-5.11 6.82-11.49c-4.47-7.15-15.84-1.14-15.84-1.14zM944.151 651.999c0 11.49-5.28 20.8-11.8 20.8-6.52 0-11.8-9.31-11.8-20.8s5.28-20.81 11.8-20.81c6.52 0 11.8 9.34 11.8 20.81z" fill="#21252B" />
                                <path d="M934.289 666.33l7.71 30.94a5.811 5.811 0 003-6.63c-1.63-7.7-3.76-21.65-5.54-28.39-.22-.68-5.17 4.08-5.17 4.08" fill="#21252B" />
                                <path opacity=".3" d="M805.998 938.29c-.64-1.85-1.299-3.75-1.919-5.69-.62-1.94-1.25-3.69-1.75-5.5a58.896 58.896 0 01-1.62-8.46c-10.94 44.54 5.759 75.91 34.449 78.12 41.9 3.22 93 5.56 113.66.14 37.67-9.89 51.492-41.3 54.792-46.47 3.3-5.17 33.26-59.95 14.9-112.68C1000.15 785.02 968.118 729 968.118 729l-30.51 10.17s5.59 26 14.43 57.19c9.72 34.26 22.74 87.85 22 119.19-.74 31.34-17.5 52.48-17.5 52.48H818.079s-3.001-3-12.101-29.76" fill="#000" />
                                <path d="M512.139 914.57l1-10.57 252.76 29.12a24.987 24.987 0 0117.117 9.907 24.99 24.99 0 014.563 19.243l-1.14 6.41-261.6-38a14.871 14.871 0 01-12.7-16.11z" className="change-color-in-svg" fill="#9d5625" />
                                <path opacity=".3" d="M512.139 914.57l1-10.57 252.76 29.12a24.987 24.987 0 0117.117 9.907 24.99 24.99 0 014.563 19.243l-1.14 6.41-261.6-38a14.871 14.871 0 01-12.7-16.11z" fill="#000" />
                                <path d="M678.162 662.711h-104.15v105.36h104.15v-105.36z" className="change-color-in-svg" fill="#9d5625" />
                                <path opacity=".2" d="M653.002 662.711h-78.99v105.36h78.99v-105.36z" fill="#fff" />
                                <path opacity=".15" d="M678.16 662.711h-25.43v105.36h25.43v-105.36z" fill="#000" />
                                <path opacity=".3" d="M622.451 662.711h-18.17v14.53h18.17v-14.53z" fill="#000" />
                                <path d="M647.889 669.82h-18.17v10.9h18.17v-10.9z" fill="#fff" />
                                <path d="M628.509 688.141h-87.2v105.36h87.2v-105.36z" className="change-color-in-svg" fill="#9d5625" />
                                <path opacity=".3" d="M611.999 688.141h-70.69v105.36h70.69v-105.36z" fill="#fff" />
                                <path opacity=".15" d="M628.511 688.141h-16.96v105.36h16.96v-105.36z" fill="#000" />
                                <path opacity=".3" d="M583.689 688.141h-15.74v15.74h15.74v-15.74z" fill="#000" />
                                <path d="M606.7 695.461H589v8.48h17.7v-8.48z" fill="#fff" />
                                <path opacity=".2" d="M1003.59 985.281s55.64-61.2 67.11-109.31c3.86 0 12.84.35 17.24 1.27 0 0-32.94 89.44-84.35 108.04zM827.121 827.42h-243.43v9.69h243.43v-9.69z" fill="#000" />
                                <path d="M534 717.211v110.21h151.43v-110.21H534zm83.6 21.79h-38.17a4.868 4.868 0 01-3.68-1.276 4.86 4.86 0 013.68-8.414h38.17a4.85 4.85 0 013.68 1.276 4.857 4.857 0 01-1.706 8.161 4.868 4.868 0 01-1.974.253z" fill="#61656D" />
                                <path opacity=".3" d="M684.88 969.109h-64.31c-9.665 0-17.5 7.835-17.5 17.5v7.39c0 9.661 7.835 17.501 17.5 17.501h64.31c9.665 0 17.5-7.84 17.5-17.501v-7.39c0-9.665-7.835-17.5-17.5-17.5z" fill="#000" />
                                <path opacity=".2" d="M685.43 717.211h-20.59v110.21h20.59v-110.21z" fill="#000" />
                                <path d="M682.458 969.109h-64.31c-9.665 0-17.5 7.835-17.5 17.5v7.39c0 9.661 7.835 17.501 17.5 17.501h64.31c9.665 0 17.5-7.84 17.5-17.501v-7.39c0-9.665-7.835-17.5-17.5-17.5z" fill="#3F4249" />
                                <path opacity=".3" d="M616.388 999.09a.909.909 0 01-.64-.267.916.916 0 01-.26-.643v-15.75a.91.91 0 011.81 0v15.75a.91.91 0 01-.91.91zM620.629 999.09a.91.91 0 01-.91-.91v-15.75a.909.909 0 011.553-.644.91.91 0 01.267.644v15.75a.91.91 0 01-.91.91zM1082.06 994.85a7.008 7.008 0 01-6.87-8.366 7.022 7.022 0 015.5-5.5 6.993 6.993 0 017.19 2.977 6.992 6.992 0 011.18 3.889 7.01 7.01 0 01-2.05 4.949 6.99 6.99 0 01-4.95 2.051zm0-12.11a5.169 5.169 0 00-2.87.863 5.198 5.198 0 00-1.9 2.311 5.167 5.167 0 001.12 5.617 5.144 5.144 0 007.92-.786 5.136 5.136 0 00-.64-6.497 5.143 5.143 0 00-3.63-1.508z" fill="#000" />
                                <path opacity=".2" d="M957.133 967.48l-.856.856 16.957 16.956.855-.855-16.956-16.957zM811.499 985.4l-1.19-.23a41.41 41.41 0 017.87-17.66l.93.78a41.06 41.06 0 00-7.61 17.11z" fill="#000" />
                                <path d="M743.559 901.899a.597.597 0 01-.59-.47c-8.28-35.49-48.71-39.47-49.12-39.5a.608.608 0 01.11-1.21c.41 0 41.71 4.1 50.19 40.43a.613.613 0 01-.45.73l-.14.02z" fill="#fff" />
                                <path d="M743.559 902.35a1.05 1.05 0 01-1-.82c-8.22-35.22-48.32-39.11-48.72-39.15a1.065 1.065 0 01-.914-1.136 1.061 1.061 0 011.084-.974c.44 0 42.06 4.12 50.61 40.78a1.06 1.06 0 01-.79 1.27l-.27.03zm-49.65-41.18a.16.16 0 00-.16.14.16.16 0 00.14.17c.41 0 41.15 4 49.52 39.85a.151.151 0 00.275.043.161.161 0 00.025-.113c-8.41-36-49.38-40-49.79-40.08l-.01-.01z" fill="#fff" />
                                <path d="M583.689 828.321a.9.9 0 01-.9-.9v-111.65l13.92-53.29a.913.913 0 01.756-.698.897.897 0 01.686.179.919.919 0 01.349.618.9.9 0 01-.031.361l-13.9 53.17v111.31a.9.9 0 01-.88.9zM603.07 828.319a.896.896 0 01-.643-.26.895.895 0 01-.267-.64v-111.56l7.88-53.28a.91.91 0 01.354-.595.909.909 0 011.266.184.904.904 0 01.17.671l-7.83 53.22v111.36a.907.907 0 01-.274.647.891.891 0 01-.656.253z" fill="#3F4249" />
                                <g opacity=".3" fill="#000">
                                    <path opacity=".3" d="M696.27 893.56h-.08l-64.11-6.41a.801.801 0 01-.725-.875.803.803 0 01.875-.725l64.12 6.45a.807.807 0 01.54.293c.135.164.2.376.18.587a.801.801 0 01-.8.68zM694.67 900h-.08l-64.12-6.41a.803.803 0 01.16-1.6l64.12 6.42a.803.803 0 01.446 1.357.802.802 0 01-.526.233zM693.072 906.39h-.07l-64.13-6.39a.807.807 0 01-.54-.293.805.805 0 01-.09-.887.793.793 0 01.79-.41l64.12 6.41a.787.787 0 01.54.284.776.776 0 01.17.586.8.8 0 01-.79.7zM691.461 912.8h-.08l-64.11-6.41a.783.783 0 01-.543-.289.81.81 0 01-.177-.591.793.793 0 01.88-.71l64.11 6.411a.8.8 0 11-.08 1.599v-.01z" />
                                </g>
                                <path opacity=".4" d="M977.809 657.25l7.07 64.9 10.12-4.15-4-61-13.19.25z" fill="#000" />
                                <path d="M858.589 940.549l-.05 22.11 28.82-.71 2.96-25.83-31.73 4.43z" fill="#FC9D9D" />
                                <path opacity=".15" d="M889.24 943.481l-15.76 1.37a6.806 6.806 0 00-4.56 6.43v11.12l-10.33.29v-21.4l31-.74-.35 2.93z" fill="#000" />
                                <path d="M856.731 959.741l.2 22.33c0 .854.156 1.701.46 2.5a6.893 6.893 0 001.3 2.11 5.785 5.785 0 001.93 1.39 5.413 5.413 0 002.27.48l68.64-.62a2.343 2.343 0 001.75-.82 2.85 2.85 0 00.71-1.94 13.366 13.366 0 00-2.19-7.17 11.365 11.365 0 00-5.571-4.46l-15.529-5.54-19.77-16.7a4.996 4.996 0 00-6.87.25l-8.62 8.28a4.686 4.686 0 01-2.18 1.21 4.498 4.498 0 01-2.44-.1l-11.05-3.7a2.125 2.125 0 00-1.09-.07 2.147 2.147 0 00-1 .47 2.396 2.396 0 00-.7.93 2.79 2.79 0 00-.25 1.17z" className="change-color-in-svg" fill="#9d5625" />
                                <path d="M856.731 959.741l.2 22.33c0 .854.156 1.701.46 2.5a6.893 6.893 0 001.3 2.11 5.785 5.785 0 001.93 1.39 5.413 5.413 0 002.27.48l68.64-.62a2.343 2.343 0 001.75-.82 2.85 2.85 0 00.71-1.94 13.366 13.366 0 00-2.19-7.17 11.365 11.365 0 00-5.571-4.46l-15.529-5.54-19.77-16.7a4.996 4.996 0 00-6.87.25l-8.62 8.28a4.686 4.686 0 01-2.18 1.21 4.498 4.498 0 01-2.44-.1l-11.05-3.7a2.125 2.125 0 00-1.09-.07 2.147 2.147 0 00-1 .47 2.396 2.396 0 00-.7.93 2.79 2.79 0 00-.25 1.17z" fill="#3F4249" />
                                <path d="M862.929 988.54l68.6-.62a2.379 2.379 0 001.75-.82 2.852 2.852 0 00.71-1.94 10.476 10.476 0 00-.34-2.73l-76.59.68a6.816 6.816 0 002 3.91 5.601 5.601 0 003.83 1.52h.04z" fill="#21252B" />
                                <path opacity=".3" d="M862.929 988.54l68.6-.62a2.379 2.379 0 001.75-.82 2.852 2.852 0 00.71-1.94 10.476 10.476 0 00-.34-2.73l-76.59.68a6.816 6.816 0 002 3.91 5.601 5.601 0 003.83 1.52h.04z" fill="#000" />
                                <path d="M890.571 960.219a.565.565 0 01-.4-.18.636.636 0 01-.14-.22.757.757 0 010-.26.816.816 0 010-.27.608.608 0 01.13-.22l4.12-5a.59.59 0 01.44-.21.645.645 0 01.44.2.746.746 0 01.19.5.772.772 0 01-.18.48l-4.11 5a.997.997 0 01-.49.19v-.01zM893.891 963a.557.557 0 01-.4-.17.636.636 0 01-.14-.22.795.795 0 01-.05-.26.691.691 0 01.05-.28.73.73 0 01.13-.22l4.12-5a.564.564 0 01.43-.2.634.634 0 01.44.19c.119.135.184.31.18.49a.765.765 0 01-.17.49l-4.12 5a1.112 1.112 0 01-.47.18zM897.158 965.81a.596.596 0 01-.4-.17.723.723 0 01-.18-.49.778.778 0 01.17-.49l4.12-5a.615.615 0 01.42-.17.63.63 0 01.42.21.726.726 0 01.18.46.707.707 0 01-.14.47l-4.12 5a.686.686 0 01-.47.18z" fill="#F4F4F4" />
                                <path d="M789.999 746.471l109.6 31.13a17.896 17.896 0 0112.81 19.83l-21.36 143.86h-34.31l5.17-125.82-136.52-14.76s-15.35-1.46-19.85-12.54l84.46-41.7z" fill="#21252B" />
                                <path opacity=".3" d="M717.531 782.25l-12 5.92c4.5 11.08 19.85 12.54 19.85 12.54l136.52 14.76-5.15 125.39 7.41.06 8.44-133.2-133.93-27.39-21.14 1.92z" fill="#000" />
                                <path d="M734.61 584.131s-11.8 17.71-15.66 25.78a85.827 85.827 0 00-5.84 17 186.915 186.915 0 00-4.32 60.57l-.37 17.34s-8.12 35.38-7.42 83.35c0 0 46.49 1.47 88.55-42.07 0 0-8.12-138-21.4-148.32l-33.54-13.65z" className="change-color-in-svg" fill="#9d5625" />
                                <path d="M744.82 527.901l-2.54 28-7.1 28 32.6 13.1 2.2-20.59 8.07 2c.27.09.55.16.82.23 5.26 1.18 8.42 1.12 10.81-7.27l2.26-10.25 3.43-1.07.19-.08a1.943 1.943 0 001-2.55l-3.93-8.86v-.5l.47-1.17a29.147 29.147 0 001.72-15.59l-.85-5.16-49.15 1.76z" fill="#FC9D9D" />
                                <path d="M794.789 526.999l-15 4.62a3.663 3.663 0 00-3 2.61l-.76 2.55c-.93 3.38-3.51 4.67-7 4.84h-3.5l-1.22 6.32a1.86 1.86 0 01-1.9 1.47 1.788 1.788 0 01-1.65-1.91l.16-2.39-8.32.33-1.6 6.56c-3.22 5.29-9.76 5.36-9.76 5.36-3.06-12.7-7.31-24.71-2.52-36.54l.12-.29c1.64-4.13 8.38-9 14.26-7.12 2.16.74 3.6-1.46 4.45-2.11 3.52-4.76 14.07-5.57 19.9-1.38l3.7 2.94c3 1.8 6.6 1.14 10.21 1 5.27-.99 8.92 7.37 3.43 13.14z" fill="#21252B" />
                                <path opacity=".15" d="M769.571 583.519l-6.57-7.61a1.297 1.297 0 01-.14-1.22 1.283 1.283 0 011.66-.68l5.86 2.26-.81 7.25zM745.852 556.391l7.64-.49a17.266 17.266 0 01-2.45-3.77 8.496 8.496 0 01-2.158 2.663 8.502 8.502 0 01-3.032 1.597z" fill="#000" />
                                <path d="M794.454 549.317c.085-1.215-.737-2.263-1.836-2.339-1.099-.077-2.059.845-2.144 2.06-.085 1.215.737 2.263 1.836 2.34 1.099.077 2.059-.846 2.144-2.061z" fill="#21252B" />
                                <path d="M789.23 548.151a.3.3 0 100-.6.3.3 0 000 .6z" fill="#fff" />
                                <path d="M760.101 558.731l.87-13.15a5.322 5.322 0 00-4.97-5.58 5.31 5.31 0 00-5.63 4.93l-.18 2.59a10.586 10.586 0 009.87 11.26l.04-.05z" fill="#FC9D9D" />
                                <path opacity=".2" d="M747.999 640.811c6 11.58 21.21 38.32 36.9 45.34l-1.64-14.15-33.62-32.45a1.033 1.033 0 00-1.845.495c-.036.272.038.547.205.765z" fill="#000" />
                                <path d="M933.349 716.89l-5.79-4.39a6.003 6.003 0 00-5.09-1l-14.28 3.5-77.3-29.38-34.89-40.19-21.53 22.57 35.24 36a18.162 18.162 0 008.77 5.29l85.73 21c10 2.74 18.31-5.36 18.31-5.36l7.85 5.77-1 2.56a4.24 4.24 0 01-2 2.27l-8.16 4.47a1.785 1.785 0 00-.86 1 1.825 1.825 0 00.145 1.487c.127.223.301.417.508.57.208.152.445.258.697.313l.46.1a1.76 1.76 0 001-.08l10.65-4.06a6.008 6.008 0 003.48-3.44l3.89-9.94-5.83-9.06z" fill="#FC9D9D" />
                                <path opacity=".15" d="M903.999 727.419l-88.16-26-35.9-39.89-6.4 5.53 36.17 36.94a18.152 18.152 0 008.77 5.29l85.73 21c8.19 2.25 15.29-2.82 17.55-4.7-11.18 5.18-17.76 1.83-17.76 1.83z" fill="#000" />
                                <path opacity=".2" d="M725 705.891l.74-61.25c0-10.1 1.59-29.53 5.4-38.88l7.51-18.43-4.06-3.2s-11.8 17.71-15.66 25.78a85.346 85.346 0 00-5.85 17 186.995 186.995 0 00-4.31 60.51l-.37 17.34s-8.1 35.44-7.4 83.41a74.656 74.656 0 0011.67-.93l20.43-4.61a278.58 278.58 0 01-8.1-76.74z" fill="#000" />
                                <path opacity=".3" d="M758.781 548.239l.149-2.2a3.526 3.526 0 00-3.26-3.72 3.402 3.402 0 00-2.76 1l-.079.07a.803.803 0 00.109 1.11.787.787 0 001.1-.11 1.813 1.813 0 011.541-.6 1.999 1.999 0 011.859 2.12l-.14 2.2a.863.863 0 00.74.85.634.634 0 00.558-.167.632.632 0 00.183-.553z" fill="#21252B" />
                                <path d="M791.131 565.1a16.733 16.733 0 01-4.87-1.5.406.406 0 00-.263-.035.403.403 0 00-.329.379.41.41 0 00.072.256 11.198 11.198 0 004.72 3.92l.67-3.02z" fill="#fff" />
                                <path d="M784.531 541.4c5.94-1.4 8.13 1.06 8.13 1.06v.63s-4.82-1.81-7.76-1.21l-.37-.48z" fill="#21252B" />
                                <path opacity=".4" d="M804.2 527.41s2.7 26.51-15.45 49.18c-5.65 3.44-26.64-2.92-25.21-24.21 1-15.08-2-25.77 2.24-28.4 4.24-2.63 25.45 4.8 38.42 3.43z" fill="#fff" />
                                <path d="M774.18 530.42s22.89 1.09 30 3.2c2.56-17.47-10.45-31-29.65-34.83-19.2-3.83-38.29 6.64-43.44 24.72-5.15 18.08-.33 25.67 4.41 31.94 3.84 5.08 9.49 8.56 24.62 6.5 10-1.35 8.93-15.74 9.91-21.18.98-5.44 1.05-10.85 4.15-10.35z" className="change-color-in-svg" fill="#9d5625" />
                                <path opacity=".2" d="M774.179 498.79a38.8 38.8 0 00-32.32 7.86 39.434 39.434 0 0123.46-2.441c16.58 3.3 28.55 13.811 29.88 27.871 2.924.283 5.822.784 8.67 1.5 2.54-17.48-10.48-30.96-29.69-34.79z" fill="#fff" />
                                <path d="M771.71 528.179c-3.1-.5-3.17 4.78-4.18 10.35-1.01 5.57.06 19.83-9.91 21.18-11 1.5-17 0-20.94-2.81 3.85 4.25 9.77 6.9 23.41 5.05 10-1.35 8.93-15.74 9.91-21.18.98-5.44 1.08-10.85 4.18-10.35 0 0 22.89 1.09 30 3.2.149-1.054.246-2.115.29-3.18-7.35-2.07-32.76-2.26-32.76-2.26z" fill="#21252B" />
                                <path d="M734.848 582.439s38.28 19.08 33 10.75c0 0 3.25 2.38.3 4.59 0 0-7.75.37-33.85-12.55.04 0-1.05-1.15.55-2.79z" fill="#fff" />
                                <path d="M769.659 610.33l30.89 35.52-28.41 24.72-26.83-35.16 24.35-25.08z" className="change-color-in-svg" fill="#9d5625" />
                                <path d="M892 942h-36v-10h37l-1 10z" fill="#21252B" />
                                <path opacity=".3" d="M892 942h-36v-10h37l-1 10z" fill="#000" />
                                <path opacity=".3" d="M867 932h-11v10h11v-10z" fill="#000" />
                                <path d="M770.512 668.43l28.37-24.5 2.12 2.07-28.45 24.57-2.04-2.14z" fill="#F4F4F4" />
                                <path d="M414.26 1001c1.531.67 2.91 1.65 4.054 2.86a12.427 12.427 0 012.606 4.23 21.922 21.922 0 011.17 9.85c-.53 6.44-4 13-7.74 18.27-5.54-4.55-7-12.73-7.54-16.92-.79-6.64 1.82-19.11 7.45-18.29M421.669 1047.57a10.628 10.628 0 01-.406-5.5 10.583 10.583 0 012.406-4.96 13.943 13.943 0 014.455-3.3c1.69-.8 3.52-1.25 5.385-1.33 1.72-.1 3.63.14 4.8 1.41.51.63.844 1.38.967 2.17.123.8.031 1.62-.267 2.37a9.126 9.126 0 01-2.92 3.78c-4.23 3.55-9 6.21-14.44 5.36M408.691 1047.77a24.84 24.84 0 00-14.11-17.77c-1.83-.81-4.2-1.3-5.64.09-1.44 1.39-1 3.79-.26 5.64a21.2 21.2 0 008.047 9.28 21.187 21.187 0 0011.843 3.27" className="change-color-in-svg" fill="#9d5625" />
                                <path d="M413.711 1011c.353 4.67.64 9.37.86 14.1.24 4.71.39 9.42.56 14.12.17 4.7.22 9.42.25 14.13.03 4.71 0 9.42-.14 14.13l-.87-28.24-.38-14.12c-.13-4.74-.21-9.45-.28-14.12zM416 1066.68c.103-2.88.489-5.74 1.15-8.54A39.768 39.768 0 01420 1050a23.202 23.202 0 015.09-7c1.086-.97 2.292-1.8 3.59-2.47a15.634 15.634 0 014.12-1.27 16.164 16.164 0 00-7.31 4.17 24.186 24.186 0 00-4.81 6.92 43.753 43.753 0 00-2.95 8 66.215 66.215 0 00-1.73 8.33zM414.48 1066.68a68.165 68.165 0 00-1.72-8.4 43.753 43.753 0 00-2.95-8 24.259 24.259 0 00-4.82-6.92 16.17 16.17 0 00-7.31-4.17c1.429.23 2.819.66 4.13 1.27 1.294.67 2.498 1.5 3.58 2.47a23.25 23.25 0 015.1 7 39.232 39.232 0 012.83 8.18 44.101 44.101 0 011.16 8.57z" fill="#21252B" />
                                <path d="M1232.26 1001c1.53.67 2.91 1.65 4.05 2.86 1.15 1.22 2.04 2.66 2.61 4.23 1.14 3.15 1.54 6.52 1.17 9.85-.53 6.44-4 13-7.74 18.27-5.54-4.55-7-12.73-7.54-16.92-.79-6.64 1.82-19.11 7.45-18.29M1239.67 1047.57c-.62-1.77-.76-3.66-.41-5.5.35-1.84 1.18-3.55 2.41-4.96a14 14 0 014.45-3.3c1.69-.8 3.52-1.25 5.39-1.33 1.72-.1 3.63.14 4.8 1.41.51.63.84 1.38.97 2.17.12.8.03 1.62-.27 2.37-.62 1.5-1.63 2.8-2.92 3.78-4.23 3.55-9 6.21-14.44 5.36M1226.69 1047.77c-.77-3.86-2.46-7.49-4.91-10.57a24.91 24.91 0 00-9.2-7.17c-1.83-.81-4.2-1.3-5.64.09-1.44 1.39-1 3.79-.26 5.64 1.71 3.82 4.51 7.05 8.05 9.28 3.54 2.24 7.66 3.37 11.84 3.27" className="change-color-in-svg" fill="#9d5625" />
                                <path d="M1231.71 1011c.35 4.67.63 9.37.86 14.1.24 4.71.39 9.42.56 14.12.17 4.7.22 9.42.25 14.13.03 4.71 0 9.42-.14 14.13l-.87-28.24-.38-14.12c-.13-4.74-.21-9.45-.28-14.12zM1234 1066.68c.1-2.88.49-5.74 1.15-8.54.65-2.81 1.61-5.54 2.85-8.14 1.24-2.63 2.97-5.01 5.09-7 1.09-.97 2.29-1.8 3.59-2.47 1.31-.61 2.69-1.04 4.12-1.27-2.76.72-5.29 2.16-7.31 4.17-2 2.01-3.62 4.35-4.81 6.92a43.782 43.782 0 00-3 8c-.74 2.74-1.3 5.52-1.68 8.33zM1232.48 1066.68c-.4-2.83-.97-5.64-1.72-8.4-.74-2.76-1.74-5.44-3-8a24.259 24.259 0 00-4.82-6.92 16.206 16.206 0 00-7.31-4.17c1.43.23 2.82.66 4.13 1.27 1.29.67 2.5 1.5 3.58 2.47 2.12 1.99 3.85 4.37 5.1 7 1.24 2.61 2.19 5.36 2.83 8.18.68 2.81 1.09 5.68 1.21 8.57z" fill="#21252B" />
                                <path d="M979 368c28.72 0 52-23.281 52-52s-23.28-52-52-52c-28.719 0-52 23.281-52 52s23.281 52 52 52zM653.089 430.711a13.883 13.883 0 01-.53 1.75c.55 4.56-46.66 2.12-46.66 2.12s-12.62 8.81-18.92.48c-12.61 3.92-83.24 5.88-112.88 1.48-25.23 2.93-66.85-2-87-2.46-20.15-.46-20.81-4.89-9.45-6.84-.13-1 .91-2.49 3-4.08 8-6.15 31.15-13.91 60.72-2-5.05-12.95 15.77-26.7 39.09-19.84-.62-7.35 8.84-6.36 8.84-6.36s-3.79-32.32 30.26-32.32c34.05 0 37.84 45 37.84 45s2.52-18.08 31.53-19.55c29.01-1.47 29 20.56 29 20.56s39.81 2.75 35.16 22.06z" fill="#fff" />
                                <path opacity=".08" d="M653.089 430.71a13.883 13.883 0 01-.53 1.75c.55 4.56-46.66 2.12-46.66 2.12s-12.62 8.81-18.92.48c-12.61 3.92-83.24 5.88-112.88 1.48-25.23 2.93-66.85-2-87-2.46-20.15-.46-20.81-4.89-9.45-6.84-.13-1 .91-2.49 3-4.08 3.21 1 5.85 3.22 6 5.93 6.14-1.86 13.57-.81 18.47 2.61 5.05-4.22 13.9-1.84 21-.08a83.404 83.404 0 0021.72 2.38c1.609.05 3.211-.212 4.72-.77 3.23-1.44 3.64-4.82 4.25-7.68a15.214 15.214 0 015.94-9.5c3.42-2.41 8.76-3.4 12.81-1.68 2 .86 3.54 2.27 5.62 3 1.48.51 3.17.65 4.58 1.29 3.58 1.6 3.86 5.47 3.7 8.69a15.572 15.572 0 0111.74 1.38c-4.45-8.14 1.88-17.3 8.85-24.29 3.15-3.14 6.65-6.29 11.35-7.89 7.14-2.43 15.83-.69 21.88 3.13 6.05 3.82 9.83 9.39 12.5 15.08 3.59-1.35 8.29-.63 10.93 1.69 12.92-3.55 28.57.18 36.7 8.75a96.154 96.154 0 0149.68 5.51z" fill="#21252B" />
                                <path d="M747.508 534h-124.44c-.41 0-.74-.45-.74-1s.33-1 .74-1h124.44c.41 0 .74.45.74 1s-.33 1-.74 1zM747.508 640h-124.44c-.41 0-.74-.45-.74-1s.33-1 .74-1h124.44c.41 0 .74.45.74 1s-.33 1-.74 1zM744.751 557h-64c-.3 0-.54-.45-.54-1s.24-1 .54-1h64c.3 0 .54.45.54 1s-.24 1-.54 1zM554.998 708h-60.77c-.41 0-.74-.45-.74-1s.33-1 .74-1h60.77c.41 0 .74.45.74 1s-.38 1-.74 1zM597.742 743h-124.48a.997.997 0 01-1-1 .997.997 0 011-1h124.48a1 1 0 110 2zM543.658 775h-49.84c-.18 0-.33-.45-.33-1s.15-1 .33-1h49.84c.19 0 .34.45.34 1s-.15 1-.34 1zM731.6 608h-46.21c-.22 0-.39-.45-.39-1s.17-1 .39-1h46.21c.22 0 .39.45.39 1s-.17 1-.39 1zM539.27 882h-95.36a1 1 0 110-2h95.36a.997.997 0 011 1 .997.997 0 01-1 1zM553.14 917h-142.7c-.8 0-1.44-.45-1.44-1s.64-1 1.44-1h142.7c.79 0 1.44.45 1.44 1s-.65 1-1.44 1z" fill="#fff" />
                                <path d="M1348.2 1068.39H195.802c-.227.02-.455-.01-.67-.09a1.628 1.628 0 01-.579-.34 1.664 1.664 0 01-.391-1.88c.091-.2.224-.39.391-.55.167-.15.364-.27.579-.34.215-.08.443-.11.67-.09H1348.2c.23-.02.46.01.67.09.22.07.41.19.58.34.17.16.3.35.39.55.09.21.14.44.14.67 0 .22-.05.45-.14.66-.09.21-.22.39-.39.55-.17.15-.36.27-.58.34-.21.08-.44.11-.67.09z" fill="#B2B6BB" />
                                <path d="M616.06 949H443.91a1 1 0 110-2h172.15a.997.997 0 011 1 .997.997 0 01-1 1z" fill="#fff" />
                            </svg>
                        </div>
                        <div className="col-lg-5 me-auto ms-auto pb-3 pt-3">
                            <h2 className="d-none h5 text-info">{t('Home.guaranteed')}</h2>
                            <h3 className="display-5 fw-bold text-capitalize text-white">{t('Home.delayDelivery')}</h3>
                            <div className="d-inline-flex flex-wrap gap-2 justify-content-center" data-pgc-define="download"><a href=""><img src="assets/img/app-store-google-play.png" width="150" /></a>
                                <a href=""><img src="assets/img/app_store.png" width="175" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}