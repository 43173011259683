import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "../i18n";

export const Header = () => {
    const { t } = useTranslation();

    return (
        <div className="fixed-nav" id="fixedNav" data-pgc="navbar">
            <nav className="bg-white navbar navbar-expand-lg navbar-light shadow-lg">
                <div className="container-fluid"> <Link onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} to="/"><img src="logo1.png" width="145" height="54" className="" /></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler35" aria-controls="" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarToggler35">
                        <ul className="mb-2 mb-lg-0 me-auto ms-auto navbar-nav">
                            <li className="nav-item"> <Link onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} className="nav-link active" to="/">{t('header.NavBar.Home')}</Link>
                            </li>
                            <li className="nav-item"> <Link onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} className="nav-link active" to="Solutions">{t('header.NavBar.Solution')}</Link>
                            </li>
                            <li className="nav-item"> <Link onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} className="nav-link active" to="Aboutus">{t('header.NavBar.Aboutus')}</Link>
                            </li>
                            <li className="nav-item"> <Link onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} className="nav-link active" to="Contact">{t('header.NavBar.Contact')}</Link>
                            </li>
                        </ul>
                        <div className="align-items-center d-inline-flex flex-wrap gap-3 py-1"> <a href="#" aria-label="facebook" className="link-light text-decoration-none"> <svg viewBox="0 0 24 24" fill="currentColor" width="1.25em" height="1.25em" className="text-primary">
                            <path d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z" />
                        </svg> </a> <a href="#" aria-label="twitter" className="link-light text-decoration-none"> <svg viewBox="0 0 24 24" fill="currentColor" width="1.25em" height="1.25em" className="text-primary">
                            <path d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z" />
                        </svg> </a> <a href="#" aria-label="instagram" className="link-light text-decoration-none"> <svg viewBox="0 0 24 24" fill="currentColor" width="1.25em" height="1.25em" className="text-primary">
                            <path d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" />
                        </svg> </a>
                            <div className="dropdown">
                                <button className="dropbtn"><Link onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} className="pe-3"><img src={i18n.resolvedLanguage === "en" ? "https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/1280px-Flag_of_the_United_States.svg.png" : "https://upload.wikimedia.org/wikipedia/en/thumb/c/c3/Flag_of_France.svg/1280px-Flag_of_France.svg.png"} width="35" height="25" /></Link><i className="fa fa-caret-down"></i>
                                </button>
                                <div className="dropdown-content">
                                    <button onClick={(e) => {
                                        e.preventDefault();
                                        i18n.changeLanguage('en')
                                        window.location.reload();
                                    }} style={{textDecoration: "none"}}>
                                        <img src="https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/1280px-Flag_of_the_United_States.svg.png" width="35" height="25" />
                                    </button>
                                    <button onClick={(e) => {
                                        e.preventDefault();
                                        i18n.changeLanguage('fr')
                                        window.location.reload();
                                    }} style={{textDecoration: "none"}}>
                                        <img src="https://upload.wikimedia.org/wikipedia/en/thumb/c/c3/Flag_of_France.svg/1280px-Flag_of_France.svg.png" width="35" height="25" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}