export const TermsOfUse = () => {
    return (
        <>
            <section className="bg-secondary  position-relative poster text-center text-white-50" style={{ backgroundImage: `url("https://images.unsplash.com/photo-1493135637657-c2411b3497ad?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDkyMnwwfDF8c2VhcmNofDN8fGxpdnJhaXNvbiUyMGJsYWNrfGVufDB8fHx8MTY5NjkzNzAwOXww&ixlib=rb-4.0.3&q=80&w=400")`, height: "250px", marginTop: "72px" }}>
                <div className="align-items-center container d-flex h-100 justify-content-center">
                    <div>
                        <h1 className="display-3 fw-bold  text-capitalize text-white" data-pg-ia='{"l":[{"trg":"now","a":"fadeInUp"}]}'>Terms of Use</h1><a href="index.html" className="link-secondary"><span>Home&nbsp;</span></a><span>&nbsp;-&gt; Terms of Use</span>
                    </div>
                    <div className="justify-content-center mb-sm-5 mt-sm-5  pb-sm-5 pt-sm-5 row">
                        <div className="col-md-10 col-xl-9 pb-5 pt-5">
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-light pb-5 pt-5">
                <div className="container pb-5 pt-5">
                    <div className="row">
                        <div className="col-lg-10 me-auto ms-auto">
                            {/* <h3 className="fw-bold h2 mb-4 text-primary">Découvrez une nouvelle manière révolutionnaire de simplifier vos livraisons</h3>
                            <img src="https://images.unsplash.com/photo-1581287053822-fd7bf4f4bfec?ixid=M3wyMDkyMnwwfDF8c2VhcmNofDR8fGFwbGljYXRpb258ZW58MHx8fHwxNjk3MDk4MTY2fDA&ixlib=rb-4.0.3q=85&fm=jpg&crop=faces&cs=srgb&w=1290&h=537&fit=crop" className="img-fluid mb-5 w-100" data-pg-ia-scene='{"dir":"a","s":"onEnter","o":"-800px","l":[{"a":"zoomIn"}]}' /> */}
                            
                            <h2 className="h3 mb-1 text-secondary">Introduction</h2>
                            <p className="mb-4">
                                Les présents termes et conditions d'utilisation régissent l'utilisation de l'application mobile de transport de marchandise <span className="text-primary">Leden Go</span> développée par Leden SARL. L'Application permet aux utilisateurs de réserver des services de transport de marchandise auprès de transporteurs professionnels.

                                En accédant ou en utilisant l'Application, vous acceptez d'être lié par les présentes Conditions. Si vous n'acceptez pas les présentes Conditions, vous ne devez pas accéder ou utiliser l'Application.

                                <h2 className="h3 mb-2 mt-2 text-secondary">Definitions</h2>

                                <p className="mb-2"> « Leden Go » désigne l'application mobile de transport de marchandise développée par Leden SARL.</p>
                                <p className="mb-2">« Utilisateur » désigne toute personne qui accède ou utilise l'Application.</p>
                                <p className="mb-2">« Transporteur » désigne tout professionnel du transport de marchandise qui utilise l'Application pour proposer ses services.</p>
                                
                                <h2 className="h3 mb-2 mt-2 text-secondary">Accès et utilisation de l'Application</h2>
                                

                                <p>L'accès et l'utilisation de l'Application sont gratuits.</p>

                                <p>Pour accéder à l'Application, vous devez créer un compte en fournissant certaines informations personnelles, telles que votre nom, votre adresse e-mail et votre numéro de téléphone.</p>
                                <p>Vous pouvez utiliser l'Application pour rechercher ou contacter des transporteurs et réserver un service de transport.</p>

                                <h2 className="h3 mb-2 mt-2 text-secondary">Responsabilités des Utilisateurs</h2>
                                
                                <p>Les Utilisateurs sont responsables de leur propre comportement et de leur propre utilisation de l'Application.</p>

                                <p>Les Utilisateurs s'engagent à utiliser l'Application de manière responsable et conformément aux lois et réglementations applicables.</p>

                                <p>Les Utilisateurs s'engagent à fournir des informations exactes et à jour lors de la création de leur compte.</p>

                                <h2 className="h3 mb-2 mt-2 text-secondary"> Responsabilités des Transporteurs</h2>

                                <p>Les Transporteurs sont responsables de leur propre conduite et de leur propre prestation de services.</p>

                                <p>Les Transporteurs s'engagent à fournir des services de transport de qualité et conformes aux lois et réglementations applicables.</p>

                                <p>Les Transporteurs s'engagent à fournir des informations exactes et à jour sur leurs services.</p>

                                <h2 className="h3 mb-2 mt-2 text-secondary">Réclamations et litiges</h2>

                                <p>Toute réclamation ou litige concernant l'Application doit être soumis à Leden SARL par écrit.</p>

                                <p>Leden SARL s'efforcera de résoudre toute réclamation ou litige de manière amiable.</p>

                                {/* <p>Si une réclamation ou un litige ne peut être résolu à l'amiable, il sera soumis à l'arbitrage conformément aux règles de l'Association des arbitres commerciaux internationaux (AAA).</p> */}

                                <h2 className="h3 mb-2 mt-2 text-secondary">Modification des Conditions</h2>

                                <p>Leden SARL se réserve le droit de modifier les présentes Conditions à tout moment.</p>

                                <p>Les modifications des Conditions seront effectives dès leur publication sur l'Application.</p>

                                <h2 className="h3 mb-2 mt-2 text-secondary">Droit applicable</h2>

                                <p>Les présentes Conditions sont régies par le droit Camerounais.</p>

                                <p>Lien vers d'autres sites web</p>

                                <p>L'Application peut contenir des liens vers d'autres sites web. Leden SARL n'est pas responsable du contenu ou des pratiques de ces sites web.</p>

                                <h2 className="h3 mb-2 mt-2 text-secondary">Renonciation</h2>

                                <p>Le fait pour Leden SARL de ne pas exercer un droit ou une disposition des présentes Conditions ne constitue pas une renonciation à ce droit ou à cette disposition.</p>

                                <h2 className="h3 mb-2 mt-2 text-secondary">Divisibilité</h2>

                                <p>Si une disposition des présentes Conditions est jugée invalide ou inapplicable, cette disposition sera réputée supprimée des présentes Conditions et les autres dispositions resteront en vigueur.</p>

                                <h2 className="h3 mb-2 mt-2 text-secondary">Intégralité</h2>

                                <p>Les présentes Conditions constituent l'intégralité de l'accord entre Leden SARL et les Utilisateurs concernant l'Application et remplacent tous les accords ou communications antérieurs ou contemporains, qu'ils soient oraux ou écrits.</p>

                                <h2 className="h3 mb-2 mt-2 text-secondary">Contact</h2>

                                <p>Pour toute question ou demande concernant les présentes Conditions, veuillez contacter [nom de l'entreprise] à l'adresse suivante :</p>

                                <p>Centre, Yaounde Cameroon</p>

                                <p>contact@cotradsarl.com</p>

                                <p>+237 655 555 555</p>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}