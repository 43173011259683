import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Solutions = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className="bg-secondary  position-relative poster text-center text-white-50" style={{ backgroundImage: `url("https://images.unsplash.com/photo-1493135637657-c2411b3497ad?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDkyMnwwfDF8c2VhcmNofDN8fGxpdnJhaXNvbiUyMGJsYWNrfGVufDB8fHx8MTY5NjkzNzAwOXww&ixlib=rb-4.0.3&q=80&w=400")`, height: "250px", marginTop: "72px" }}>
                <div className="align-items-center container d-flex h-100 justify-content-center">
                    <div>
                        <h1 className="display-3 fw-bold  text-capitalize text-white" data-pg-ia='{"l":[{"trg":"now","a":"fadeInUp"}]}'>{t('header.NavBar.Solution')}</h1><Link to="/" className="link-secondary"><span>{t('header.NavBar.Home')}&nbsp;</span></Link><span>&nbsp;-&gt; {t('header.NavBar.Solution')}</span>
                    </div>
                    <div className="justify-content-center mb-sm-5 mt-sm-5  pb-sm-5 pt-sm-5 row">
                        <div className="col-md-10 col-xl-9 pb-5 pt-5">
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-light pb-5 pt-5 text-center">
                <div className="container pb-5 pt-5">
                    <div className="row">
                        <div className="col-lg-10 me-auto ms-auto">
                            <h2 className="h6 mb-1 text-secondary">Leden Go</h2>
                            <h3 className="fw-bold h2 mb-4 text-primary">{t('Solution.bigTitle')}</h3>
                            <img src="https://images.unsplash.com/photo-1581287053822-fd7bf4f4bfec?ixid=M3wyMDkyMnwwfDF8c2VhcmNofDR8fGFwbGljYXRpb258ZW58MHx8fHwxNjk3MDk4MTY2fDA&ixlib=rb-4.0.3q=85&fm=jpg&crop=faces&cs=srgb&w=1290&h=537&fit=crop" className="img-fluid mb-5 w-100" data-pg-ia-scene='{"dir":"a","s":"onEnter","o":"-800px","l":[{"a":"zoomIn"}]}' />
                            <p className="fw-light mb-4">{t('Solution.smallTitle')}</p>
                            <div className="d-inline-flex flex-wrap gap-2 justify-content-center" data-pgc="download"><a href=""><img src="assets/img/app-store-google-play.png" width="176" /></a>
                                <a href=""><img src="assets/img/app_store.png" width="200" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="d-flex justify-content-center">
                <section className="align-items-center col-lg-10 d-flex flex-column justify-content-center">
                    <div className="container">
                        <main>
                            <section id="comment-ca-marche" className="align-items-center d-flex flex-column">
                                <h2 className="align-items-center d-flex fw-bold h2 mb-3 text-white"><span className="display-4 fw-bold me-2 text-secondary">1.</span><span className="bg-primary pe-1 ps-1">{t('Solution.itWorks')}</span></h2>
                                <video width="100%" src="assets/NOUYADJAM%20&%20ASSOCIATES%20bilingue%20final.mp4" height="500" controls></video>
                            </section>
                            <section id="telechargez" className="align-items-center d-flex flex-column text-center">
                                <h2 className="align-items-center d-flex fw-bold h2 mb-3 text-white"><span className="display-4 fw-bold me-2 text-secondary">2.</span><span className="bg-primary pe-1 ps-1">{t('Solution.downloadNow')}</span></h2>
                                <p>{t('Solution.modern')} <strong>Leden Go</strong> {t('Solution.now')}. {t('Solution.available')}, <strong>Leden Go</strong> {t('Solution.available')} {t('Solution.ready')}</p>
                            </section>
                        </main>
                    </div>
                </section>
            </section>
        </>
    );
}