import { useTranslation } from "react-i18next";

export const Contact = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className="bg-secondary position-relative poster section_header text-center text-white-50" style={{ backgroundImage: `url('https://images.unsplash.com/photo-1493135637657-c2411b3497ad?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDkyMnwwfDF8c2VhcmNofDN8fGxpdnJhaXNvbiUyMGJsYWNrfGVufDB8fHx8MTY5NjkzNzAwOXww&ixlib=rb-4.0.3&q=80&w=400')`, height: "250px" }}>
                <div className="align-items-center container d-flex h-100 justify-content-center">
                    <div>
                        <h1 className="display-3 fw-bold  text-capitalize text-white" data-pg-ia='{"l":[{"trg":"now","a":"fadeInUp"}]}'>{t('header.NavBar.Contact')}</h1>
                        <a href="index.html" className="link-secondary"><span>{t('header.NavBar.Home')}&nbsp;</span></a>
                        <span>&nbsp;-&gt; {t('header.NavBar.Contact')}</span>
                    </div>
                    <div className="justify-content-center mb-sm-5 mt-sm-5  pb-sm-5 pt-sm-5 row">
                        <div className="col-md-10 col-xl-9 pb-5 pt-5">
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-light  pb-5">
                <div className="container pb-4 pt-4">
                    <div className="align-items-center row">
                        <div className="col-lg-6 ms-auto order-lg-2 py-3 text-center" data-pg-ia-scene='{"dir":"a","s":"onEnter","o":"-1000px","l":[{"a":"zoomIn"}]}'>
                            <div className="container pb-5 pt-5" data-pg-ia='{"l":[{"trg":"now","a":"slideInRight"}]}'>
                                <div className="bg-white p-4 p-lg-5 shadow">
                                    <h2 className="mb-4 text-primary">{t('Home.form.title')}</h2>
                                    <div className="mb-3">
                                        <input type="text" className="form-control pb-3 pt-3 rounded-0" placeholder={t('Home.form.name')} id="name" />
                                    </div>
                                    <div className="mb-3">
                                        <input type="email" className="form-control pb-3 pt-3 rounded-0" placeholder={t('Home.form.email')} id="email" />
                                    </div>
                                    <div className="mb-3">
                                        <textarea className="form-control rounded-0" rows="6" placeholder={t('Home.form.message')} id="message"></textarea>
                                    </div>
                                    <button type="button" className="btn btn-primary pb-3 pt-3 rounded-0 text-uppercase w-100" id="submitButton" onclick="submit()"> <span id="spinner" className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ display: "none" }}></span> <span id="text_span"> {t('Home.form.button')} </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 order-lg-1 py-3" data-pg-ia-scene='{"dir":"a","s":"onEnter","l":[{"a":"slideInLeft"}],"o":"-1000px"}'>
                            <h3 className="bg-white fw-bold h2 mb-1 text-secondary">{t('Globals.contactUs')}</h3>
                            <p className="fw-normal mb-4 text-primary">{t('Contact.details')}</p>
                            <p className="fw-normal mb-1 text-primary" style={{ fontFamily: "Allan, cursive", fontSize: "38px", fontWeight: "700 !important" }}>{t('Globals.email')} : contact@cotradsarl.com</p>
                            <p className="fw-normal mb-4 text-primary" style={{ fontFamily: "Allan, cursive", fontSize: "38px", fontWeight: "700 !important" }}>{t('Globals.number')} :&nbsp; +237 6555 555 555</p>
                        </div>
                    </div>
                    <div className="bg-dark d-flex flex-column justify-content-center pb-5 pt-5 text-center">
                        <div className="container pb-4 pt-4">
                            <div className="d-inline-flex flex-wrap"> <a href="#" aria-label="facebook" className="btn btn-secondary m-2 p-3 rounded-circle shadow"> <svg viewBox="0 0 24 24" fill="currentColor" width="40" height="40" className="d-block">
                                <path d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z" />
                            </svg> </a> <a href="#" aria-label="twitter" className="btn btn-secondary m-2 p-3 rounded-circle shadow"> <svg viewBox="0 0 24 24" fill="currentColor" width="40" height="40" className="d-block">
                                <path d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z" />
                            </svg> </a> <a href="#" aria-label="instagram" className="btn btn-secondary m-2 p-3 rounded-circle shadow"> <svg viewBox="0 0 24 24" fill="currentColor" width="40" height="40" className="d-block">
                                <path d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" />
                            </svg> </a> <a href="#" aria-label="linkedin" className="btn btn-secondary m-2 p-3 rounded-circle shadow"> <svg viewBox="0 0 24 24" fill="currentColor" width="40" height="40" className="d-block">
                                <path d="M6.94 5a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z" />
                            </svg> </a> <a href="#" aria-label="youtube" className="btn btn-secondary m-2 p-3 rounded-circle shadow"> <svg viewBox="0 0 24 24" fill="currentColor" width="40" height="40" className="d-block">
                                <path d="M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5l6-3.5-6-3.5v7z" />
                            </svg> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}