import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Aboutus = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className="bg-secondary  position-relative poster text-center text-white-50" style={{ backgroundImage: `url('https://images.unsplash.com/photo-1493135637657-c2411b3497ad?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDkyMnwwfDF8c2VhcmNofDN8fGxpdnJhaXNvbiUyMGJsYWNrfGVufDB8fHx8MTY5NjkzNzAwOXww&ixlib=rb-4.0.3&q=80&w=400')`, height: "250px", marginTop: "72px" }}>
                <div className="align-items-center container d-flex h-100 justify-content-center">
                    <div>
                        <h1 className="display-3 fw-bold  text-capitalize text-white" data-pg-ia='{"l":[{"trg":"now","a":"fadeInUp"}]}'>{t('header.NavBar.Aboutus')}</h1><Link to="/" className="link-secondary"><span>{t('header.NavBar.Home')}&nbsp;</span></Link><span>&nbsp;-&gt; {t('header.NavBar.Aboutus')}</span>
                    </div>
                    <div className="justify-content-center mb-sm-5 mt-sm-5  pb-sm-5 pt-sm-5 row">
                        <div className="col-md-10 col-xl-9 pb-5 pt-5">
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5 pt-5 text-center text-lg-start text-secondary">
                <div className="col-lg-10 container pb-4 pt-4">
                    <div className="align-items-center row">
                        <div className="col-lg-6 ms-auto order-lg-2 py-3 text-center">
                            <div className="align-items-center justify-content-center row">
                                <img src="https://images.unsplash.com/photo-1601887031610-d72bf3987fb2?ixid=M3wyMDkyMnwwfDF8c2VhcmNofDI5fHxsaXZyYWlzb258ZW58MHx8fHwxNjk3MDk5NzgwfDA&ixlib=rb-4.0.3q=85&fm=jpg&crop=faces&cs=srgb&w=245&h=368&fit=crop" className="col-5 img-fluid" width="170" data-pg-ia='{"l":[{"trg":"now","a":"zoomIn"}]}' />
                                <img src="https://images.unsplash.com/photo-1573165706511-3ffde6ef1fe3?ixid=M3wyMDkyMnwwfDF8c2VhcmNofDI0fHxlcXVpcGV8ZW58MHx8fHwxNjk3MDk5NzM5fDA&ixlib=rb-4.0.3q=85&fm=jpg&crop=faces&cs=srgb&w=245&h=368&fit=crop" className="col-6 img-fluid" width="200" data-pg-ia='{"l":[{"trg":"now","a":"zoomIn","rpt":"1"}]}' />
                            </div>
                        </div>
                        <div className="col-lg-5 order-lg-1 py-3" data-pg-ia='{"l":[{"trg":"now","a":"bounceInLeft"}]}'>
                            <h2 className="display-4 fw-bold text-primary">Leden Go</h2>
                            <h3 className="fw-light mb-4 text-secondary">{t('Home.welcome')}</h3>
                            <p className="d-none mb-4 text-muted">{t('About.welcome')}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-light d-none pb-5 text-center text-lg-start">
                <div className="container pb-4 pt-4 text-center">
                    <div className="align-items-center row">
                        <div className="col-lg-6 ms-auto me-auto">
                            <h2 className="h6 mb-1 text-secondary">Leden Go</h2>
                            <h3 className="fw-bold h2 mb-3 text-primary">{t('About.historyTitle')}</h3>
                            <div style={{ width: "25%", height: "5px" }} className="bg-secondary me-auto ms-auto" data-pgc-define="divider"></div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-10 container pb-4 pt-4">
                    <div className="align-items-center row">
                        <div className="col-lg-6 pb-3 pt-3">
                            <img src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwyMDkyMnwwfDF8c2VhcmNofDJ8fHdvcmt8ZW58MHx8fA&ixlib=rb-1.2.1&q=80&w=1080" className="img-fluid" alt="..." width="540" height="360" data-pg-ia-scene='{"dir":"a","s":"onEnter","l":[{"t":"$main #gt# section:nth-of-type(3) #gt# div:nth-of-type(2) #gt# div:nth-of-type(1) #gt# div:nth-of-type(1) #gt# .img-fluid","a":"fadeInLeft"}],"o":"-1800px"}' />
                        </div>
                        <div className="col-lg-6 pb-3 pt-3" data-pg-ia-scene='{"dir":"a","s":"onEnter","l":[{"a":"fadeInRight"}],"o":"-1800px"}'>
                            <div className="pb-3 pt-3">
                                <h3 className="fw-bold h5 text-primary">{t('About.origineTitle')}</h3>
                                <p className="fw-light mb-0">{t('About.origineDesc')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="align-items-center row">
                        <div className="col-lg-6 order-lg-2 pb-3 pt-3" data-pg-ia-scene='{"dir":"a","s":"onEnter","l":[{"a":"fadeInRight"}],"o":"-1000px"}'>
                            <img src="https://images.unsplash.com/photo-1593556037473-f4ef4bd55a15?ixid=M3wyMDkyMnwwfDF8c2VhcmNofDl8fGV2b2x1dGlvbnxlbnwwfHx8fDE2OTcwOTk5MDJ8MA&ixlib=rb-4.0.3q=85&fm=jpg&crop=faces&cs=srgb&w=540&h=360&fit=crop" className="img-fluid" alt="..." width="540" height="360" />
                        </div>
                        <div className="col-lg-6 order-lg-1 pb-3 pt-3" data-pg-ia-scene='{"dir":"a","s":"onEnter","l":[{"a":"fadeInLeft"}],"o":"-1000px"}'>
                            <div className="pb-3 pt-3">
                                <h3 className="fw-bold h5 text-primary">{t('About.evolutionTitle')}</h3>
                                <p className="fw-light mb-0">{t('About.evolutionDesc')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-white pb-5 text-center text-lg-start">
                <div className="container pb-4 pt-4 text-center">
                    <div className="align-items-center row">
                        <div className="col-lg-6 ms-auto me-auto">
                            <h2 className="h6 mb-1 text-secondary">Leden Go</h2>
                            <h3 className="fw-bold h2 mb-3 text-primary">{t('About.leden.title')}</h3>
                            <div style={{ width: "25%", height: "5px" }} className="bg-secondary me-auto ms-auto" data-pgc-define="divider"></div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-10 container pb-4 pt-4">
                    <div className="align-items-center row">
                        <div className="col-lg-6 pb-3 pt-3">
                            <img src="https://images.unsplash.com/photo-1638866411782-5f59287c19e1?ixid=M3wyMDkyMnwwfDF8c2VhcmNofDR8fG1pc3Npb258ZW58MHx8fHwxNjk3MTAwMTAxfDA&ixlib=rb-4.0.3q=85&fm=jpg&crop=faces&cs=srgb&w=540&h=360&fit=crop" className="img-fluid" alt="..." width="540" height="360" data-pg-ia-scene='{"dir":"a","s":"onEnter","l":[{"a":"fadeInLeft"}],"o":"-1000px"}' />
                        </div>
                        <div className="col-lg-6 pb-3 pt-3" data-pg-ia-scene='{"dir":"a","s":"onEnter","l":[{"a":"fadeInRight"}],"o":"-1000px"}'>
                            <div className="pb-3 pt-3">
                                <h3 className="fw-bold h5 text-primary">{t('About.leden.mission.title')}</h3>
                                <p className="fw-light mb-0">{t('About.leden.mission.desc')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="align-items-center row">
                        <div className="col-lg-6 order-lg-2 pb-3 pt-3">
                            <img src="https://images.unsplash.com/photo-1474631245212-32dc3c8310c6?ixid=M3wyMDkyMnwwfDF8c2VhcmNofDJ8fHZhbGV1cnxlbnwwfHx8fDE2OTcxMDAxMjF8MA&ixlib=rb-4.0.3q=85&fm=jpg&crop=faces&cs=srgb&w=540&h=360&fit=crop" className="img-fluid" alt="..." width="540" height="360" data-pg-ia-scene='{"dir":"a","s":"onEnter","l":[{"a":"fadeInRight"}],"o":"-1000px"}' />
                        </div>
                        <div className="col-lg-6 order-lg-1 pb-3 pt-3" data-pg-ia-scene='{"dir":"a","s":"onEnter","l":[{"a":"fadeInLeft"}],"o":"-1000px"}'>
                            <div className="pb-3 pt-3">
                                <h3 className="fw-bold h5 text-primary">{t('About.leden.valeur.title')}&nbsp;</h3>
                                <p className="fw-light mb-0">{t('About.leden.valeur.desc')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-dark pb-5 pt-5 text-white-50">
                <div className="container pb-5 pt-5">
                    <h3 className="fw-bold h2 mb-3 text-center text-decoration-underline text-light" style={{ fontFamily: 'Alex Brush, cursive', fontSize: "55px", lineHeight: "59px" }} data-pg-ia-scene='{"dir":"a","s":"onEnter","l":[{"a":"jackInTheBox"}],"o":"-1000px"}'>{t('About.whyUs')}</h3>
                    <div className="justify-content-center row">
                        <div className="col-md-6 col-xl-4 pb-3 pt-3">
                            <div>
                                <h2 className="align-items-center d-flex fw-bold h4 mb-3 text-white"><span className="display-4 fw-bold me-2 text-secondary">1.</span><span>{t('Home.securityTitle')}</span></h2>
                                <p>{t('Home.securityDesc')}</p>
                                <a href="#" className="d-none link-primary">{t('Globals.learnMore')}</a>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4 pb-3 pt-3">
                            <div>
                                <h2 className="align-items-center d-flex fw-bold h4 mb-3 text-white"><span className="display-4 fw-bold me-2 text-secondary">2.</span><span>{t('Home.fiabiliteTitle')}</span></h2>
                                <p>{t('Home.fiabiliteDesc')}</p>
                                <a href="#" className="d-none link-primary">{t('Globals.learnMore')}</a>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4 pb-3 pt-3">
                            <div>
                                <h2 className="align-items-center d-flex fw-bold h4 mb-3 text-white"><span className="display-4 fw-bold me-2 text-secondary">3.</span><span>{t('Home.rapideTitle')}</span></h2>
                                <p>{t('Home.rapideDesc')}</p>
                                <a href="#" className="d-none link-primary">{t('Globals.learnMore')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-light pb-5 pt-5 text-center text-muted">
                <div className="col-lg-6 me-auto ms-auto text-center">
                    <h2 className="h6 mb-1 text-secondary">Leden Go</h2>
                    <h3 className="fw-bold h2 mb-3 text-decoration-none text-primary">{t('About.realisation')}</h3>
                    <div style={{ width: "25%", height: "5px" }} className="bg-secondary me-auto ms-auto" data-pgc-define="divider"></div>
                </div>
                <div className="container pb-5 pt-5">
                    <img src="https://images.unsplash.com/photo-1581287053822-fd7bf4f4bfec?ixid=M3wyMDkyMnwwfDF8c2VhcmNofDR8fGFwbGljYXRpb258ZW58MHx8fHwxNjk3MDk4MTY2fDA&ixlib=rb-4.0.3q=85&fm=jpg&crop=faces&cs=srgb&w=1290&h=537&fit=crop" className="img-fluid mb-5 w-100" data-pg-ia-scene='{"dir":"a","s":"onEnter","o":"-800px","l":[{"a":"zoomIn"}]}' />
                    <div className="col-lg-9 me-auto ms-auto">
                        <h3 className="fw-bold h1 text-dark" data-pg-ia-scene='{"dir":"a","s":"onEnter","l":[{"a":"slideInLeft"}],"o":"-1400px"}'>{t('About.explore')}</h3>
                        <p className="fw-light mb-4" data-pg-ia-scene='{"dir":"a","s":"onEnter","l":[{"a":"slideInRight"}],"o":"-1539px"}'>{t('About.exploreDesc')}</p>
                        <Link to="Solution" className="btn btn-primary pb-2 pe-4 ps-4 pt-2">{t('Globals.readMore')}</Link>
                    </div>
                </div>
            </section>
            <section className="bg-light pb-5 pt-5 text-center" data-pgc="partner">
                <div className="col-lg-6 ms-auto me-auto">
                    <h2 className="h6 mb-1 text-secondary">Leden Go</h2>
                    <h3 className="fw-bold h2 mb-3 text-primary">{t('Home.partnerTitle')}</h3>
                    <div style={{ width: "25%", height: "5px" }} className="bg-secondary me-auto ms-auto" data-pgc="divider"></div>
                </div>
                <div className="container">
                    <div className="justify-content-center row">
                        <div className="col-lg-3 col-sm-6 pb-3 pt-3">
                            <img src="assets/img/partner1.png" width="75" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}